<template>
  <div class="notFound">
    <div>404 NotFound</div>
    <div>お探しのページは見つかりませんでした。</div>
  </div>
</template>

<style lang="scss" scoped>
.notFound {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(100vh / 5);
  :first-child {
    font-size: 48px;
    font-weight: bold;
    color: var(--label-secondary);
  }
}
</style>
