<template>
  <Loader :flag="flag.isLoading.get">
    <div class="container" v-if="detail && !flag.isLoading.get">
      <div>
        <div :class="s.name">{{ detail.vehicle.name }}<span>{{ detail.brand.label }}</span></div>
        <div class="fs-xs mt-3">{{ optionString }}</div>
      </div>
      <spacer :y="2"/>
      <div :class="s.two_col">
        <div :class="s.left">
          <div :class="s.img" :style="`background-image: url(${detail.images[showImgIdx]?.url})`"/>
          <spacer :y="2"/>
          <ul :class="s.imgs">
            <li v-for="(img, i) in detail.images" :key="img.id">
              <div
                @click="showImgIdx = i"
                class="pointer"
                :style="`background-image: url(${img.url})`"/>
            </li>
          </ul>
        </div>
        <div :class="s.right" class="pt-2">
          <!-- price -->
          <div :class="s.price_wrap">
            <div>
              <div class="fw-bold">支払総額</div>
              <div class="ms-3">
                <div :class="s.price_all" v-if="!detail.price_ask">{{ detail.price_total?.toLocaleString() }}<span>万円</span></div>
                <div :class="s.price_all" v-if="detail.price_ask">応相談</div>
              </div>
            </div>
            <div v-if="!detail.price_ask && detail.price_body">
              <div class="d-flex">
                <div class="fs-xs me-2">車両本体価格</div>
                <div>
                  <span class="fw-bold fs-4">{{ detail.price_body?.toLocaleString() }}</span>
                  <span class="fs-xs ms-1">万円</span>
                </div>
              </div>
              <div class="d-flex ms-3">
                <div class="fs-xs me-2">諸費用</div>
                <div>
                  <span class="fw-bold fs-4">{{ (detail.price_total - detail.price_body)?.toLocaleString() }}</span>
                  <span class="fs-xs ms-1">万円</span>
                </div>
              </div>
            </div>
          </div>  

          <!-- tags -->
          <ul :class="s.tags">
            <li v-if="detail.maintenance"><i class="fa-solid fa-tag"/>法定整備：整備付<span v-if="detail.maintenance_comment">（{{ detail.maintenance_comment }}）</span></li>
            <li><i class="fa-solid fa-tag"/>{{ formatWarranty(detail) }}</li>
          </ul> 

          <!-- 詳細（一部） -->
          <ul :class="s.excerpt" class="n2br">
            <li><span>年式</span><span>{{ detail.regist_year }}年</span></li>
            <li><span>走行距離</span><span v-html="formatMileage(detail)"/></li>
            <li><span>車検</span><span>{{ formatInspection(detail) }}</span></li>
            <li><span>排気量</span><span>{{ detail.displacement }}cc</span></li>
            <li><span>修復歴</span><span>{{ detail.repair_history ? 'あり' : 'なし' }}</span></li>
          </ul> 

          <!-- shop -->
          <div :class="s.shop" v-if="detail.shops?.length">
            <div class="mb-2">
              <div class="fs-xs fw-bold fc-gray text-decoration-underline">お電話でのお問い合わせ</div>
              <div class="mt-1 fs-xs fw-bold">{{ detail.shops[0].name }}</div>
              <div class="fc-red fs-5 fw-bold">{{ detail.shops[0].tel }}</div>
            </div>  

            <ul class="fs-xs" :class="s.shop_data">
              <li class="d-flex align-items-strech">
                <div class="fc-gray fw-bold" :class="s.shop_data_label">販売元</div>
                <div class="flex-grow-1">{{ detail.shops[0].name }}</div>
              </li>
              <li class="d-flex align-items-strech">
                <div class="fc-gray fw-bold" :class="s.shop_data_label">住所</div>
                <div class="flex-grow-1">{{ formatAddress(detail.shops[0]) }}</div>
              </li>
            </ul> 

            <!-- <basic-btn
              class="mb-2"
              :width="'full'"
              :icon="'fa-solid fa-calendar-days'">来店・試乗予約</basic-btn>
            <basic-btn
              :width="'full'"
              :icon="'fa-regular fa-envelope'">在庫確認・見積もり依頼</basic-btn> -->
          </div>
        </div>
      </div>
      <spacer :y="4"/>  

      <!-- details -->
      <div class="bdr">
        <div class="bdr-b fw-bold py-3 text-center">車両詳細</div>
        <spacer :y="4"/>
        <div :class="s.car_detail_wrap">
          <!-- main -->
          <div class="flex-1">
            <ul>
              <li
                v-for="(tItem, i) in tableItems"
                :key="i">
                <div
                  class="fw-bold pb-2 mb-3 bdr-b bdr-bold"
                  :class="i === 0 ? '' : 'mt-5'">{{ tItem.label }}</div>
                <Table
                  :items="adjustedTableItems(tItem.items)" />
              </li>
            </ul>
            <spacer :y="2"/>
            <OptionList :optionValue="detail.options"/>
          </div>  

          <!-- side -->
          <div :class="s.shop_side" class="bdr p-3">
            <div class="fc-gray fs-xs fw-bold bdr-b pb-2 bdr-bold">販売店詳細</div>
            <div class="mt-2">
              <div class="fs-xs fw-bold">{{ detail.shops[0].name }}</div>
              <ul class="mt-2">
                <li class="py-2 bdr-b">
                  <div class="fs-xs">住所</div>
                  <div class="fs-xs fw-bold">{{ formatAddress(detail.shops[0]) }}</div>
                </li>
                <li class="py-2 bdr-b">
                  <div class="fs-xs">営業時間</div>
                  <div class="fs-xs fw-bold">{{ detail.shops[0].hours }}</div>
                </li>
                <li class="py-2 bdr-b">
                  <div class="fs-xs">定休日</div>
                  <div class="fs-xs fw-bold">{{ detail.shops[0].holiday }}</div>
                </li>
                <!-- <li class="py-2 bdr-b">
                  <div class="fs-xs">販売店のHP</div>
                  <a
                    href="/"
                    class="fs-xs fw-bold fc-black text-decoration-underline">（株）ＴＳＣ　買取直販　つくば店</a>
                </li> -->
              </ul>
              <div class="mt-3">
                <div class="fs-xs fw-bold fc-gray">お電話でのお問い合わせ</div>
                <div class="fs-5 fc-red fw-bold">{{ detail.shops[0].tel }}</div>
              </div>
            </div>
          </div>
        </div>  

        <spacer :y="8"/>  

        <!-- contact -->
        <div class="bg-gray-secondary p-5 text-center" v-if="detail.shops?.length">
          <div class="fs-xs fw-bold fc-gray">お電話でのお問い合わせ</div>
          <div class="mt-3 fw-bold">{{ detail.shops[0].name }}</div>
          <div class="fs-3 fw-bold fc-red">{{ detail.shops[0].tel }}</div>
          <!-- <ul class="d-flex justify-content-center flex-wrap mt-4">
            <li class="w-50 px-2">
              <basic-btn
                class="me-3"
                :width="'full'"
                :icon="'fa-solid fa-calendar-days'">来店・試乗予約</basic-btn>
            </li>
            <li class="w-50 px-2">
              <basic-btn
                :width="'full'"
                :icon="'fa-regular fa-envelope'">在庫確認・見積もり依頼</basic-btn>
            </li>
          </ul> -->
        </div>  

        <spacer :y="8"/>  

        <!-- shop -->
        <div class="px-4" v-if="detail.shops?.length">
          <div class="fw-bold pb-2 mb-3 bdr-b bdr-bold">販売店情報</div>
          <div class="mb-3 fw-bold fs-lg">{{ detail.shops[0].name }}</div>
          <div :class="s.shop_img_wrap">
            <div
              v-if="detail.shops[0].images[0]"
              :class="s.shop_img"
              :style="`background-image: url(${detail.shops[0].images[0].url});`"/>
            <div class="flex-1">
              <div class="fw-bold">{{ detail.shops[0].summary }}</div>
              <div class="fs-xs mt-2">{{ detail.shops[0].description }}</div>
              <div class="mt-2 pt-2 fs-xs">
                <div>所在地：〒{{ detail.shops[0].zip }} {{ formatAddress(detail.shops[0]) }}</div>
                <div>電話番号：{{ detail.shops[0].tel }}<span v-if="detail.shops[0].fax">（FAX：{{ detail.shops[0].fax }}）</span></div>
                <div>定休日：{{ detail.shops[0].holiday }}</div>
                <div>営業時間：{{ detail.shops[0].hours }}</div>
              </div>
            </div>
          </div>
          <ul :class="s.explanations" v-if="detail.shops[0].explanations.length">
            <li
              v-for="item in detail.shops[0].explanations"
              :key="item.id">
              <div>{{ item.title }}</div>
              <div class="fs-xs n2br" v-html="item.content"/>
            </li>
            <li>
              <div>企業情報</div>
              <div class="fs-xs n2br">
                <div>屋号：{{ detail.shops[0].company.name }}</div>
                <div>所在地：〒{{ detail.shops[0].company.zip }} {{ formatAddress(detail.shops[0].company) }}</div>
                <div v-if="detail.shops[0].company.business">事業内容：{{ detail.shops[0].company.business }}</div>
                <div v-if="detail.shops[0].company.license_num">古物商許可番号：{{ detail.shops[0].company.license_num }}</div>
                <div v-if="detail.shops[0].company.employees_num">従業員数 ：{{ detail.shops[0].company.employees_num }}名</div>
                <div v-if="detail.shops[0].company.holder_name">資格所有者 ：{{ detail.shops[0].company.holder_name }}</div>
              </div>
            </li>
          </ul>
        </div>  


        <!-- この販売店の同一車種 -->
        <div class="px-4" v-if="sameShopCars.sameVehicles.length">
          <spacer :y="8"/>  
          <div class="fw-bold pb-2 mb-3 bdr-b bdr-bold">この販売店の同一車種</div>
          <ul :class="s.cards">
            <li v-for="carData in sameShopCars.sameVehicles" :key="carData.id">
              <router-link :to="`/sales-car/${carData.slug}`">
                <CarCard
                  :carData="carData"/>
              </router-link>
            </li>
          </ul>
        </div>  

        <spacer :y="8"/>  

        <!-- この販売店の新着車種 -->
        <div class="px-4" v-if="sameShopCars.new.length">
          <div class="fw-bold pb-2 mb-3 bdr-b bdr-bold">この販売店の新着車種</div>
          <ul :class="s.cards">
            <li v-for="carData in sameShopCars.new" :key="carData.id">
              <router-link :to="`/sales-car/${carData.slug}`">
                <CarCard
                  :carData="carData"/>
              </router-link>
            </li>
          </ul>
        </div>  

        <spacer :y="8"/>
      </div>  

      <spacer :y="8"/>  

      <!-- contact -->
      <div class="bg-gray-secondary p-5 text-center" v-if="detail.shops?.length">
        <div class="fs-xs fw-bold fc-gray">お電話でのお問い合わせ</div>
        <div class="mt-3 fw-bold">{{ detail.shops[0].name }}</div>
        <div class="fs-3 fw-bold fc-red">{{ detail.shops[0].tel }}</div>
        <!-- <ul class="d-flex justify-content-center flex-wrap mt-4">
          <li class="w-50 px-2">
            <basic-btn
              class="me-3"
              :width="'full'"
              :icon="'fa-solid fa-calendar-days'">来店・試乗予約</basic-btn>
          </li>
          <li class="w-50 px-2">
            <basic-btn
              :width="'full'"
              :icon="'fa-regular fa-envelope'">在庫確認・見積もり依頼</basic-btn>
          </li>
        </ul> -->
      </div>  

      <spacer :y="12"/>
    </div>
    <div v-if="!etail && !flag.isLoading.get">
      <NotFound />
    </div>
  </Loader>
</template>

<script>
import { mapState } from 'vuex';
import {
  // BasicBtn,
  Spacer,
  Table,
  OptionList,
  CarCard,
  Loader,
} from '@/components/parts/';
import NotFound from '@/views/404.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'cars-detail',
  mixins: [cf],
  components: {
    // BasicBtn,
    Spacer,
    Table,
    OptionList,
    CarCard,
    Loader,
    NotFound,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: false,
        },
      },
      detail: null,
      showImgIdx: 0,
      sameShopCars: {
        sameVehicles: [],
        new: [],
      },
      tableItems: [
        {
          label: '車両状態',
          items: [
            {
              key: 'status_type',
              label: '状態',
            },
            {
              key: 'mileage',
              label: '走行距離',
            },
            {
              key: 'repair_history',
              label: '修復歴',
              type: 'bool',
              valueTemplate: ['なし', 'あり'],
            },
            {
              key: 'inspection',
              label: '車検',
            },
            {
              key: 'maintenance',
              label: '法定整備',
              type: 'bool',
              valueTemplate: ['なし', 'あり'],
            },
          ],
        },
        {
          label: '基本仕様',
          items: [
            {
              key: 'regist_year',
              label: '年式（初度登録）',
            },
            {
              key: 'shape_type',
              label: '車体タイプ',
            },
            {
              key: 'model',
              label: 'モデル',
            },
            {
              key: 'grade',
              label: 'グレード',
            },
            {
              key: 'displacement',
              label: '排気量',
              unit: 'cc',
            },
            {
              key: 'capacity',
              label: '乗車定員',
            },
            {
              key: 'engine',
              label: '燃料',
            },
            {
              key: 'door_num',
              label: 'ドア',
            },
            {
              key: 'mission',
              label: 'ミッション',
            },
            {
              key: 'color',
              label: '車体色',
            },
            {
              key: 'body_num',
              label: '車体番号下3桁',
            },
            {
              key: 'size_body',
              label: '車体サイズ',
            },
            {
              key: 'size_luggage',
              label: '荷台寸法',
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getDetail();
  },
  computed: {
    ...mapState(['helper']),
    carLabels() {
      if (!this.helper.master.labels) return null;
      return this.helper.master.labels.cars;
    },
    optionString() {
      if (!this.carLabels || !this.detail) return '';
      let selectedOptions = [];
      const options = JSON.parse(this.detail.options);
      const optionData = this.carLabels.options.front;
      optionData.forEach((ctg, i) => {
        const selected = ctg.options.filter((item) => options?.[i].includes(item.name));
        selectedOptions = [...selectedOptions, ...selected];
      });
      let str = '';
      selectedOptions.forEach((opt, i) => {
        str = `${str}${i === 0 ? '' : '／'}${opt.label}`;
      });
      return str;
    },
  },
  methods: {
    adjustedTableItems(arr) {
      const newArray = [];
      arr.forEach((item) => {
        let value = this.detail[item.key] || '-';
        const fromMasterKeys = ['status_type', 'engine', 'mission', 'capacity', 'shape_type'];
        if (item.key === 'mileage') value = this.formatMileage(this.detail);
        if (item.key === 'inspection') value = this.formatInspection(this.detail);
        if (item.key === 'regist_year') value = `${this.detail.regist_year}年(${this.convertYearToJapaneseEra(this.detail.regist_year)}年) ${this.detail.regist_month}月`;
        if (item.key === 'grade') value = `${this.detail.grade || '-'}${this.detail.grade_comment ? `\n<span class="fs-xs fc-gray fw-normal">${this.detail.grade_comment}</span>` : ''}`;
        if (item.key === 'color') value = this.formatColor(this.detail, this.helper.master.labels?.cars.colors);
        if (fromMasterKeys.includes(item.key)) value = this.carLabels[item.key][this.detail[item.key]];
        else if (item.unit) value = `${value}${item.unit}`;

        // flagの場合：valueTemplateがあればそれに則り、なければ 0 => 「-」、1 => 「○」
        if (item.type === 'bool') {
          if (item.valueTemplate) value = item.valueTemplate[this.detail[item.key]];
          else value = this.detail[item.key] ? '○' : '-';
        }
        if (item.key === 'maintenance' && this.detail.maintenance_comment) value = `${value}(${this.detail.maintenance_comment})`;

        newArray.push({ label: item.label, value });
      });
      return newArray;
    },
    filteredOptions(arr, typeId) {
      return arr.filter((item) => item.option_type_id === typeId);
    },
    getDetail() {
      this.flag.isLoading.get = true;
      const params = {
        slug: this.$route.params.slug,
      };
      this.axios({
        method: 'GET',
        url: '/v1/salesCar/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.detail = res.detail;
          if (this.detail) {
            if (this.detail?.shops?.[0]) {
              this.getSalesCars('sameVehicles');
              this.getSalesCars('new');
            }
            this.setBreadCrumb();
          }
          this.flag.isLoading.get = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get = false;
        });
    },
    getSalesCars(type) {
      const params = {
        shop_id: this.detail.shops[0].id,
        excludeIds: [this.detail.id],
        limit: 10,
      };
      if (type === 'sameVehicles') params.vehicle_id = this.detail.vehicle.id;
      this.axios({
        method: 'GET',
        url: '/v1/salesCar/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.sameShopCars[type] = res.salesCars.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    setBreadCrumb() {
      const brand = this.detail.brand;
      const vehicle = this.detail.vehicle;
      const data = {
        items: [
          {
            label: `${brand.label}一覧（販売）`,
            path: `/sales-car/?brand=${brand.name}`,
          },
          {
            label: `${vehicle.name}一覧（販売）`,
            path: `/sales-car/?brand=${brand.name}&vehicle=${vehicle.name}`,
          },
          {
            label: `${vehicle.name}（${brand.label}）`,
            path: `/sales-car/${this.detail.slug}/`,
          },
        ],
      };
      this.$store.dispatch('breadCrumb/setItem', data);
    },
  },
};
</script>

<style lang="scss" module="s">
.name {
  font-size: 20px;
  font-weight: 700;
  span {
    font-size: 14px;
    font-weight: normal;
    margin-left: 12px;
  }
}

.two_col {
  display: flex;
  .left {
    flex: 1;
  }
  .right {
    margin-left: 32px;
    min-width: 300px;
    width: calc(100% * ( 1 / 3 ));
  }

  @include smView {
    display: block;
    .right {
      width: 100%;
      margin-left: 0;
    }
  }
}

.img {
  width: 100%;
  padding-top: calc(100% * ( 2 / 3 ));
  background-position: center;
  background-size: cover;
}

.imgs {
  display: flex;
  flex-wrap: wrap;
  $mgn: 12px;
  margin-right: -$mgn;
  margin-bottom: -$mgn;
  > li {
    width: calc(100% / 8);
    padding: 0 $mgn $mgn 0;
    > * {
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-position: center;
    }
  }
}

.price {
  &_wrap {
    > * {
      display: flex;
      justify-content: space-between;
    }
    @include smView {
      display: block;
      margin-bottom: 24px;
      > * {
        justify-content: flex-start;
        align-items: center;
        > * {
          align-items: center;
        }
      }
    }
  }
  &_all {
    font-weight: 700;
    font-size: 42px;
    color: var(--primary-red);
    span {
      font-size: 14px;
      font-weight: normal;
      margin-left: 4px;
      color: var(--label-primary);
    }
  }
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > li {
    font-size: 12px;
    padding: 4px 12px;
    background-color: var(--bg-gray);
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    i {
      margin-right: 8px;
    }
  }
}

.excerpt {
  margin-top: 20px;
  > li {
    display: flex;
    &:not(:first-child) {
      margin-top: 8px;
    }
    > * {
      display: block;
      font-size: 12px;
      &:first-child {
        width: 5em;
      }
      &:not(:first-child) {
        font-weight: 700;
      }
    }
  }
}

.shop {
  margin-top: 24px;
  padding: 12px;
  border: 1px solid var(--border-gray);
  &_data {
    padding-top: 12px;
    margin-bottom: 12px;
    border-top: 1px solid var(--border-gray);
    > li {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    &_label {
      border-right: 1px solid var(--border-gray);
      margin-right: 6px;
      padding-right: 6px;
    }
  }
}

.car_detail_wrap {
  display: flex;
  align-items: flex-start;
  padding: 0 32px;
  @include smView {
    flex-flow: column;
    padding: 0 18px;
  }
}

.shop_side {
  margin-left: 12px;
  width: calc(100% / 4);
  max-width: 300px;
  @include smView {
    width: 100%;
    max-width: none;
    margin-left: 0;
    margin-top: 56px;
  }
}

.cards {
  overflow-x: scroll;
  display: flex;
  padding-bottom: 12px;

  > li {
    width: 200px;
    flex-shrink: 0;
    transition: all .3s;

    a {
      display: block;
      color: var(--label-primary);
      padding: 6px;
    }

    &:hover {
      opacity: .5;
    }
  }
}

.shop_img_wrap {
  display: flex;
  align-items: flex-start;
  @include smView {
    display: block;
  }
}
.shop_img {
  width: 25%;
  padding-top: calc(25% / 4 * 3);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
  @include smView {
    width: 100%;
    padding-top: calc(100% / 4 * 3);
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.explanations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 24px;
  > li {
    width: calc((100% - 12px) / 2);
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    overflow: hidden;
    &:nth-child(n + 3) {
      margin-top: 12px;
    }
    > :first-child {
      padding: 12px 12px 8px;
      font-weight: bold;
      border-bottom: 1px solid var(--border-gray);
      background-color: var(--bg-gray-secondary);
    }
    > :last-child {
      padding: 8px 12px;
    }
  }

  @include smView {
    display: block;
    > li {
      width: 100%;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}
</style>
