/**
* ログイン管理
*/

import axios from '@/plugins/axios';
import router from '@/router';
import cf from '@/mixins/commonFunctions';

const projectName = process.env.VUE_APP_PROJECTNAME;

// 閲覧にログインが必要のページ名（配下含め）
const requiredLoginPageNames = [
  'admin',
  'account',
  'edit',
];


const actions = {
  /** ログインしているかのチェック */
  check({ commit, dispatch, state }) {
    const ls = cf.getLocalStorage(projectName);
    // 現在の環境チェック
    dispatch('helper/checkEnv', null, { root: true });

    const currentPath = router.options.history.location;

    // localStorageが存在しない（未サインナップ）
    if (!ls || !ls.auth) {
      commit('changeCheckedStatus', false);
      dispatch('user/setUserData', { hasntToken: true }, { root: true });

      const isRedirect = requiredLoginPageNames.some((page) => currentPath.includes(page));

      // ログインページへリダイレクト
      if (isRedirect) {
        alert('本ページを閲覧するにはログインしている必要があります。\nログインページへリダイレクトします。');
        const current = router.options.history;
        const ref = { path: current.location };
        // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
        cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, projectName);
        dispatch('redirect2Login');
      }

      return;
    }

    // ログインstateがなく、かつauthがあれば認証APIを叩く
    if (!state.isLogin && ls.auth) {
      if (ls.auth.loginType === 'default') {
        dispatch('authCheck', {
          actionType: 'check',
          auth: ls.auth,
        });
      } else if (ls.auth.loginType === 'line') {
        dispatch('tokenCheck', {
          actionType: 'check',
          auth: ls.auth,
        });
      }
    }
  },

  /**
   * auth情報を使ってログインチェックし
   * 取得したユーザーデータをstateへ格納
   * @param obj data
   *   auth: obj
   *   actionType: 'check' || 'login'
   * login:checkではlocalstorageから
   * login:submitでは取得したtokenをdataに格納して渡す
   */
  authCheck({ commit, dispatch }, data) {
    const ls = cf.getLocalStorage(projectName);
    const Authorization = `${ls.auth.token.type} ${ls.auth.token.token}`;
    axios({
      method: 'POST',
      url: '/v1/user/loginCheck',
      headers: { Authorization },
    })
      .then((response) => {
        // user.flagが1以外はログイン無効
        const user = response.data.user;
        if (user.flag !== 1) {
          // ログイン時には失敗の挙動
          if (data.actionType === 'login') dispatch('loginFailed');
          cf.deleteLocalStorage(projectName, 'auth');
          commit('changeLoginStatus', { isLogin: false });
          return;
        }
        // チェックOKの場合
        const successParams = {
          loginType: 'default',
          actionType: data.actionType,
          detail: user,
        };

        dispatch('loginSuccess', successParams);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);

        if (data.actionType === 'check') {
          // ログインチェック時
          const errors = error.response.data.errors;

          // 期限切れ
          if (errors[0].message.includes('E_UNAUTHORIZED_ACCESS')) {
            if (ls.auth) { // ログイン画面へリダイレクト
              alert('ログイン有効期限が切れています。\nログイン画面へリダイレクトします');
              const current = router.options.history;
              const ref = { path: current.location };
              // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
              cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, projectName);
              dispatch('redirect2Login');
              cf.deleteLocalStorage(projectName, 'auth');
            }
          } else {
            // DBとの接続に失敗
            alert('データの取得に失敗しました。リロードをお試しください');
          }
        } else if (data.actionType === 'login') {
          // ログイン直後
          dispatch('loginFailed');
          commit('changeLoginStatus', { isLogin: false });
          cf.deleteLocalStorage(projectName, 'auth');
          alert('ログインで問題が発生しました\nお手数ですが管理者へお知らせください');
        }
        // state.isLoadingを完了に
        commit('changeCheckedStatus', false);
      });
  },

  /**
   * token情報を使ってLINEログインチェックし
   * 取得したLINEユーザーデータをstateへ格納
   * @param obj data
   *   auth: obj
   *   actionType: 'check' || 'login'
   * login:checkではlocalstorageから
   * login:submitでは取得したtokenをdataに格納して渡す
   */
  tokenCheck({ commit, dispatch }, data) {
    const ls = cf.getLocalStorage(projectName);
    const token = ls.auth.token;

    axios({
      method: 'POST',
      url: '/v1/user/lineLoginCheck',
      data: { token },
    })
      .then((response) => {
        const res = response.data;
        if (!res || !res.user) {
          if (data.actionType === 'login') {
            dispatch('loginFailedStatus');
          } else dispatch('loginFailed');
          return;
        }
        // チェックOKの場合
        const successParams = {
          loginType: 'line',
          actionType: data.actionType,
          detail: res.user,
        };

        dispatch('loginSuccess', successParams);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);

        if (data.actionType === 'check' && ls.auth) {
          alert('ログインの確認中に問題が発生しました。\n再度ログインを行ってください。');
          const current = router.options.history;
          const ref = { path: current.location };
          // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
          cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, projectName);
          dispatch('redirect2Login');
        } else if (data.actionType === 'login') {
          // ログイン直後
          alert('ログインで問題が発生しました\nお手数ですが管理者へお知らせください');
          dispatch('loginFailed');
        }

        cf.deleteLocalStorage(projectName, 'auth');
        // state.isLoginをfalse
        commit('changeLoginStatus', { isLogin: false });
        // state.isLoadingを完了に
        commit('changeCheckedStatus', false);
      })
      .finally(() => {
        dispatch('hideLoading');
      });
  },

  /**
   * ログイン成功時の処理
   * @param {*} data
   *  loginType: 'default' || 'line'
   *  actionType: 'check' || 'login'
   *  detail: obj
   */
  loginSuccess({ commit, dispatch }, data) {
    // login.isFailedをリセット
    commit('changeLoginErrorStatus', 0);
    // loginStatusを更新
    commit('changeLoginStatus', { type: data.loginType, isLogin: true });
    // ユーザー情報をstate.userにセット（user/index.js）
    dispatch('user/setUserData', data.detail, { root: true });
    // システムマスターの判別
    dispatch('helper/checkSystemMaster', null, { root: true });
    // ログイン制限state解除
    commit('changeBannedStatus', { isBanned: false, unbannedTime: null });

    if (data.actionType === 'login') {
      const ls = cf.getLocalStorage(projectName);
      // リファラの有無でリダイレクト先が変わる
      if (ls && ls.loginRef) {
        const ref = JSON.parse(ls.loginRef);
        const pushParams = { ...ref };
        if (ls.query) pushParams.query = ls.query;
        router.push(pushParams);
      } else {
        router.push({ path: '/' });
      }
    }
    // state.isLoadingを完了に
    commit('changeCheckedStatus', false);
    dispatch('hideLoading');
  },

  /**
   * ログインページのsubmit押下
   * API通信してIDとPASSをpost or LINEログイン
   * 認証に成功したらtokenをlocalStorageへ保存
   * @param obj data
   *   loginType:      'default' || 'line'
   *   email:          str (default)
   *   password:       str (default)
   *   code:           str (line)
   *   redirect_uri:   str (line)
   *   client_id:      str (line)
   *   client_secret:  str (line)
   */
  submit({ commit, dispatch }, data) {
    // ローディング開始
    dispatch('showLoading');
    // ログイン開始時点で一度login.isFailedをリセット
    commit('changeLoginErrorStatus', 0);
    axios({
      method: 'post',
      url: '/v1/user/login',
      data,
    })
      .then((response) => {
        const res = response.data;
        const token = res.token;

        if (res.status === 200) {
          const auth = { token, loginType: data.loginType };
          // tokenをlocalStorageに保存
          cf.saveLocalStorage({ auth }, projectName);
          if (data.loginType === 'default') dispatch('authCheck', { actionType: 'login', auth });
          else if (data.loginType === 'line') dispatch('tokenCheck', { actionType: 'login', auth });
        } else if (data.loginType === 'line') {
          // lineログインの場合
          alert('ログインに失敗しました。再度やり直してください');
          router.push('/login/');
        } else if (data.loginType === 'default') {
          if (res.message === 'user not found') commit('changeLoginErrorStatus', 1);
        }

      })
      .catch((error) => {
        dispatch('loginFailed');
        if (error.response) {
          const errRes = error.response.data;
          console.log(errRes);
          // パスワード違い
          if (data.loginType === 'default' && errRes?.error?.includes('E_INVALID_AUTH_PASSWORD')) {
            commit('changeLoginErrorStatus', 2);
          }

          if (errRes.isBanned) {
            commit('changeBannedStatus', { isBanned: errRes.isBanned, unbannedTime: errRes.unbannedTime });
          } else if (errRes.failedStatus) {
            commit('changeBannedStatus', { isBanned: errRes.failedStatus.isBanned, unbannedTime: errRes.failedStatus.unbannedTime });
          } else {
            commit('changeBannedStatus', { isBanned: false, unbannedTime: null });
          }
        } else console.log(error);
      });
  },


  /** ログイン失敗 */
  loginFailed({ commit, dispatch }) {
    dispatch('hideLoading');
    commit('changeVibrationStatus', true);
    setTimeout(() => { commit('changeVibrationStatus', false); }, 500);
  },


  /** 有効期限切れ時のログイン画面リダイレクト */
  redirect2Login() {
    router.push({ path: '/login/' });
  },


  /** トップへリダイレクト */
  redirect2Top() {
    router.push({ path: '/' });
  },


  /** ログアウト */
  logout() {
    const ls = cf.getLocalStorage(projectName);
    if (ls.auth.token) {
      let Authorization = null;
      const data = {};
      if (ls.auth.loginType === 'default') Authorization = `${ls.auth.token.type} ${ls.auth.token.token}`;
      if (ls.auth.loginType === 'line') data.token = ls.auth.token;
      const endpoint = ls.auth.loginType === 'default' ? 'logout' : 'lineLogout';
      axios({
        method: 'post',
        url: `/v1/user/${endpoint}`,
        headers: Authorization ? { Authorization } : {},
        data,
      })
        .then(() => {
          // localStorageの更新
          cf.deleteLocalStorage(projectName, 'loginRef');
          cf.deleteLocalStorage(projectName, 'auth');
          // ログインページへリダイレクト・全storeリセットのため強制リロード
          router.push({ path: '/login/' });
          setTimeout(() => { router.go(router.currentRoute.path); }, 500);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    }
  },

  showLoading({ dispatch }) {
    dispatch('modal/loadings/showModal', { modalName: 'modalLoadingBallScaleRippleMultiple' }, { root: true });
  },

  hideLoading({ dispatch }) {
    dispatch('modal/loadings/hideModal', null, { root: true });
  },
};

const mutations = {
  /** auth || token チェック済みステート */
  changeCheckedStatus(state, bool) {
    state.isLoading = bool;
  },

  /** ログインステート */
  changeLoginStatus(state, { type, isLogin }) {
    state.type = type || null;
    state.isLogin = isLogin;
  },

  /** ログイン失敗時のバイブフラグステート */
  changeVibrationStatus(state, bool) {
    state.vibration = bool;
  },

  /** ログイン制限時のフラグ・日時ステート */
  changeBannedStatus(state, { isBanned: bool, unbannedTime: datetime }) {
    state.isBanned = bool;
    state.unbannedTime = datetime;
  },

  /** ログイン失敗時のエラー内容ステート */
  changeLoginErrorStatus(state, number) {
    if (number === 0) {
      state.isFailed = 0;
    } else {
      state.isFailed = number;
    }
  },

};

const state = {
  type: null,
  isLoading: true,
  isLogin: false,
  vibration: false,
  isBanned: false,
  unbannedTime: null,
  isFailed: 0,
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
