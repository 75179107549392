<template>
  <div>
    <div :class="s.key_visual">
      <img src="/img/kv01.jpg" alt="カーバンク群馬">
    </div>
    <spacer :y="8" :smY="4"/>
    <div class="container-xl">

      <div>
        <ul :class="s.banner" class="fs-xs">
          <li>
            <router-link to="/purchase-car/">
              <div class="mb-1">買取対象車両を掲載中！</div>
              <div><span :class="s.lg">買取中の車種</span>を見る</div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/sales-car/">
              <div class="mb-1">ブランドや価格など様々な条件で検索可能！</div>
              <div><span :class="s.lg">販売中の車両</span>を検索</div>
            </router-link>
          </li> -->
          <!-- <li>
            <router-link to="/shop/">
              <div class="mb-1">お住まいの都道府県・地域から</div>
              <div><span :class="s.lg">販売店</span>を探す</div>
            </router-link>
          </li> -->
        </ul>
      </div>

      <spacer :y="15" :smY="7"/>

      <div class="container-xs">
        <div class="fs-lg fw-bold bdr-b bdr-bold pb-2 text-center mb-3">お知らせ</div>
        <Loader
          :disableMinHeight="true"
          :minHeight="100"
          :flag="flag.isLoading.get.news">
          <ul>
            <li
              v-for="item in news"
              :key="item.id"
              class="bdr-b">
              <router-link
                :to="`/news/${item.id}`">
                <div
                  class="fc-black d-flex p-3">
                  <div class="flex-shrink-0 me-3">{{ formatTimestamp(item.publish_date, 'YYYY/MM/DD') }}</div>
                  <div class="flex-1">{{ item.title }}</div>
                </div>
              </router-link>
            </li>
          </ul>
        </Loader>
        <div class="d-flex justify-content-center mt-4">
          <router-link
            class="fc-black text-decoration-underline fs-sm"
            to="/news/">お知らせ一覧へ</router-link>
        </div>
      </div>

      <spacer :y="15" :smY="7"/>

      <div>
        <div class="fs-lg fw-bold bg-gray px-3 pb-1 pt-2 bdr-b bdr-bold rounded-top-2"><i class="fa-regular fa-car me-3"/>買取車両</div>
        <spacer :y="4"/>
        <div>
          <Loader
            :flag="flag.isLoading.get.purchaseCar"
            :minHeight="100">
            <FlexList
              v-if="!flag.isLoading.get.purchaseCar && purchaseCars.length"
              :count="6"
              :smCount="2"
              :mgnY="8"
              :mgnX="8">
              <li
                v-for="carData in purchaseCars"
                :key="carData.id">
                <router-link
                  :to="`/purchase-car/${carData.slug}/`"
                  class="h-100 bdr overflow-hidden rounded-2 d-block">
                  <div :class="s.purchase_img" :style="`background-image: url(${carData.vehicle?.images[0]?.url || '/img/noimg.png'});`"/>
                  <div class="fc-black p-3">
                    <div class="fw-bold fs-lg">{{ carData.vehicle.name }}</div>
                    <div class="fs-xs">{{ carData.brand.label }}</div>
                    <div class="mt-2">
                      <div class="fw-bold fs-xxs fc-gray">買取相場</div>
                      <div class="fc-red fs-sm">
                        <span class="fw-bold text-nowrap">{{ carData.market_price_min || carData.market_price_min === 0 ? `${carData.market_price_min?.toLocaleString()}万円` : '未設定' }}</span>
                        〜
                        <span class="fw-bold text-nowrap">{{ carData.market_price_max ? `${carData.market_price_max?.toLocaleString()}万円` : '未設定' }}</span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </li>
            </FlexList>
            <div v-else-if="!flag.isLoading.get.salesCar">買取車両はありません。</div>
          </Loader>
          <div class="d-flex justify-content-end mt-4">
            <router-link
              class="fc-black text-decoration-underline fs-sm"
              to="/purchase-car/">>> 全ての買取車両を見る</router-link>
          </div>
        </div>
      </div>

      <spacer :y="10" :smY="7"/>

      <div>
        <!-- フリーワード -->
        <div>
          <div class="fw-bold fc-gray"><i class="fa-regular fa-key me-2"/>キーワードから探す</div>
          <div class="separator mt-2"/>
          <spacer :y="2"/>
          <form class="input-group" @submit.prevent="push2CarList('keyword', keyword)">
            <input
              class="form-control"
              placeholder="キーワードから探す"
              type="text"
              v-model="keyword">
            <button
              class="btn btn-red"
              type="button"><i class="fa-regular fa-magnifying-glass"/></button>
          </form>
        </div>

        <spacer :y="10" :smY="7"/>

        <!-- brand -->
        <div>
          <div class="fw-bold fc-gray"><i class="fa-regular fa-gear me-2"/>メーカーから探す</div>
          <div class="separator mt-2"/>
          <spacer :y="2"/>
          <Loader
            :flag="flag.isLoading.get.brand"
            :minHeight="100">
            <ul :class="s.brands">
              <li
                v-for="(brand, i) in brands"
                :key="i">
                <div
                  class="bdr rounded-2 p-2 pointer"
                  @click="push2CarList('brand', encodeURIComponent(brand.name))">
                  <div
                    class="bg-img bg-img-contain"
                    :class="s.img"
                    :style="`background-image: url(${brand.images[0]?.url});`"/>
                </div>
              </li>
            </ul>
          </Loader>
        </div>
        <spacer :y="10" :smY="7"/>

        <!-- <ul :class="s.text_links">
          <li
            v-for="(item, i) in textLinkOptions"
            :key="i">
            <div class="fw-bold fc-gray"><i class="me-2" :class="item.icon"/>{{ item.label }}から探す</div>
            <div class="separator mt-2"/>
            <spacer :y="2"/>
            <ul class="row">
              <li
                v-for="(opt, j) in this[`${item.name}Options`]"
                :key="j"
                class="col-4 mb-2">
                <div
                  class="fs-xs pointer link-opacity"
                  @click="push2CarList(item.queryKey, opt.value)">
                  {{ opt.label }}
                </div>
              </li>
            </ul>
          </li>
        </ul> -->
      </div>
    </div>

    <!-- お問い合わせ -->
    <router-link
      to="/contact/"
      :class="s.contact">
      <i class="fa-regular fa-envelope me-2"/>お問い合わせはこちら
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Loader,
  FlexList,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'Home',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    FlexList,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: {
            brand: false,
            salesCar: false,
            purchaseCar: false,
          },
        },
      },
      keyword: '',
      news: [],
      brands: [],
      salesCars: [],
      purchaseCars: [],
      regions: [
        {
          label: '北海道・東北',
          name: 'tohoku',
          value: ['北海道', '青森県', '岩手県', '秋田県', '宮城県', '山形県', '福島県'],
        },
        {
          label: '関東',
          name: 'kanto',
          value: ['茨城県', '栃木県', '群馬県', '千葉県', '埼玉県', '東京都', '神奈川県'],
        },
        {
          label: '中部',
          name: 'chubu',
          value: ['新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県'],
        },
        {
          label: '近畿',
          name: 'kinki',
          value: ['三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県'],
        },
        {
          label: '中国・四国',
          name: 'chugokushikoku',
          value: ['鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県'],
        },
        {
          label: '九州・沖縄',
          name: 'kyusyu',
          value: ['福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'],
        },
      ],
      textLinkOptions: [
        {
          label: '価格',
          name: 'price',
          queryKey: 'priceMax',
          icon: 'fa-regular fa-yen',
        },
        {
          label: '走行距離',
          name: 'mileage',
          queryKey: 'mileage',
          icon: 'fa-regular fa-weight-scale',
        },
      ],
    };
  },
  created() {
    this.getBrands();
    this.getSalesCars();
    this.getPurchaseCars();
    this.getNews();
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    bodyTypes() {
      if (!this.helper.master.labels) return [];
      return this.helper.master.labels.cars.shape_type;
    },
    prefs() {
      return this.helper.master.prefs || [];
    },
    priceOptions() {
      const options = [];
      // 100万円までは10万刻み
      for (let i = 10; i <= 100; i += 10) { options.push({ label: `~ ${i}万円`, value: i }); }
      // 100~1000万円までは100万刻み
      for (let i = 200; i <= 1000; i += 100) { options.push({ label: `~ ${i}万円`, value: i }); }
      return options;
    },
    mileageOptions() {
      //  1万km以上は1万刻み
      const options = [];
      const exceptions = [500, 1000, 3000, 5000, 7000];
      exceptions.forEach((num) => { options.push({ label: `${num}km以下`, value: num }); });
      for (let i = 1; i <= 15; i += 1) {
        options.push({ label: `${i}万km以下`, value: i * 10000 });
      }
      return options;
    },
    capacityOptions() {
      //  1万km以上は1万刻み
      const options = [];
      if (this.helper.master.labels) {
        const capacityData = this.helper.master.labels.cars.capacity;
        Object.keys(capacityData).forEach((key) => {
          options.push({ label: capacityData[key], value: key });
        });
      }
      return options;
    },
  },
  methods: {
    /** ログアウト */
    logout(e) {
      e.preventDefault();
      this.$store.dispatch('user/login/logout');
    },
    getNews() {
      this.flag.isLoading.get.news = true;
      const params = {
        isReleased: 1,
        limit: 5,
      };
      this.axios({
        method: 'GET',
        url: '/v1/news/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.news = res.news.data;
          this.flag.isLoading.get.news = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get.news = false;
        });
    },
    getBrands() {
      this.flag.isLoading.get.brand = true;
      const params = {
        // withVehicles: 1,
        // hasPurchaseCars: 1,
        countries: [1],
        orderBy: 'id',
        order: 'asc',
      };
      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = this.adjustBrand(res.brands.data);
          this.flag.isLoading.get.brand = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get.brand = false;
        });
    },
    adjustBrand(brands) {
      // 「日本その他」は表示しない、brand.nameはdecodeする
      return brands
        .filter((brand) => brand.label !== '日本その他')
        .map((brand) => {
          brand.name = decodeURIComponent(brand.name);
          return brand;
        })
        .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    },
    adjustedPrefs(arr) {
      if (!this.prefs.length) return [];
      return this.prefs.filter((pref) => arr.includes(pref.value));
    },
    push2CarList(key, val) {
      if (key === 'keyword' && !val) return;
      this.$router.push({
        path: '/purchase-car/',
        query: {
          [key]: encodeURIComponent(val),
        },
      });
    },

    getSalesCars() {
      this.flag.isLoading.get.salesCar = true;
      const params = {
        limit: 6,
      };
      this.axios({
        method: 'GET',
        url: '/v1/salesCar/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.salesCars = res.salesCars.data;
          this.flag.isLoading.get.salesCar = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get.salesCar = false;
        });
    },

    getPurchaseCars() {
      this.flag.isLoading.get.purchaseCar = true;
      const params = {
        flags: [1],
        limit: 6,
      };

      this.axios({
        method: 'GET',
        url: '/v1/purchaseCar/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.purchaseCars = res.purchaseCars.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get.purchaseCar = false;
        });
    },
  },
};
</script>


<style lang="scss" module="s">
.key_visual {
  width: 100%;
  height: 30vw;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.banner {
  display: flex;
  > li {
    $mgn: 24px;
    width: 100%;
    // width: calc((100% - $mgn * 1) / 2);
    // width: calc((100% - $mgn * 2) / 3);
    &:not(:last-child) {
      margin-right: $mgn;
    }
    a {
      display: block;
      background-color: rgb(255, 250, 220);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
      font-weight: bold;
      padding: 24px 56px 24px 32px;
      color: var(--label-primary);
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      position: relative;
      transition: all .3s;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 50px;
        width: 20px;
        height: 20px;
        border: 4px solid;
        $bdr-color: rgb(239, 226, 153);
        border-color: $bdr-color $bdr-color transparent transparent;
        transform: rotate(45deg) translateY(-60%);
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0) 0 0 0 0;
        transform: translate(1px, 1px);
      }
    }
    .lg {
      font-size: 20px;
      margin-right: 4px;
      color: var(--primary-red);
    }
  }

  @include smView {
    display: block;
    > li {
      width: 100%;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 12px;
      }

      a {
        padding: 12px 12px;
      }
    }
  }
}

.brands {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px -6px 0;
  > li {
    width: calc(100% / 6);
    padding: 6px;
    .img {
      width: 100%;
      padding-top: 60%;
    }
  }

  @include smView {
    > li {
      width: calc(100% / 3);
    }
  }
}

.text_links {
  display: flex;
  > li {
    width: calc((100% - 24px * 1) / 2);
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
  @include smView {
    display: block;
    > li {
      width: 100%;
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 56px;
      }
    }
  }
}

.purchase_img {
  width: 100%;
  padding-top: 75%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--bg-gray);
}

.contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-red);
  color: #ffffff;
  font-weight: bold;
  padding: 24px;
  border-radius: 8px;
  z-index: 10;
  animation: slideInFromBottom 1s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: background-color .3s;
  cursor: pointer;

  &:hover {
    background-color: rgb(246, 143, 138);;
  }

  @keyframes slideInFromBottom {
    0% { right: -100%; }
    100% { right: 20px; }
  }
}
</style>
