/**
 * axiosの共通設定
 */
import axios from 'axios';

export default axios.create({
  headers: {
    Authorization: process.env.VUE_APP_TOKEN,
  },
  baseURL: process.env.VUE_APP_APIBASE,
  responseType: 'json',
});
