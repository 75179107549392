<template>
  <div :class="[isDisableMinHight ? s.loaderWrapper : [s.loaderWrapper, s.minHeight]]" :style="style">
    <slot />
    <div
      :class="[s.loaderSimple, mini ? s.mini : '']"
      v-if="flag" />
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Boolean,
      default: false,
    },
    isDisableMinHight: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Number,
      default: 300,
    },
  },
  computed: {
    style() {
      return `
        --min-height: ${this.minHeight}px;
      `;
    },
  },
};
</script>

<style lang="scss" module="s">
.loaderSimple {
  width: 2em;
  height: 2em;
  animation: rotation 1s infinite linear;
  border: 1px solid rgba(black, 0.2);
  border-top-color: rgba(black, 0.7);
  border-radius: 50%;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
  z-index: 1;
  &.mini {
    width: 1.2em;
    height: 1.2em;
  }
}

.loaderWrapper {
  position: relative;
}

.minHeight {
  min-height: var(--min-height);
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
</style>
