<template>
  <div class="container sm">
    <spacer :y="12"/>
    <h2 class="text-center fs-4 fw-bold">ログイン</h2>
    <spacer :y="6"/>

    <!-- LINEログイン -->
    <div>
      <div
        :class="s.line_login"
        @click="lineLogin">
        <img src="/img/line/line_88.png">
        <span :class="s.text">LINEでログイン</span>
        <span :class="s.mask"/>
      </div>
    </div>

    <spacer :y="4"/>
    <div class="text-center">または</div>
    <spacer :y="4"/>

    <!-- 通常ログイン -->
    <div>
      <form
        :class="user.login.vibration ? s.vibration : ''"
        autocomplete="off">
        <div class="form-signin">
          <div class="form-floating">
            <input
              id="mail"
              type="email"
              name="mail"
              class="form-control"
              placeholder="メールアドレス"
              v-model.trim="$v.mail.$model"
              v-bind:class="{ input_error: validationFlags.mail }"
              v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
              >
            <label for="mail">メールアドレス</label>
          </div>
          <div class="form-floating">
            <input
              id="password"
              type="password"
              name="password"
              class="form-control"
              placeholder="パスワード"
              v-model.trim="$v.password.$model"
              v-bind:class="{ input_error: validationFlags.password }"
              v-on:input="invalidsCheck('password', $v.password.$invalid)"
              >
            <label for="password">パスワード</label>
          </div>
          <p
            v-if="$v.mail.$dirty
              && $v.mail.required.$invalid">メールアドレスを入力してください</p>
          <p
            v-if="$v.mail.$dirty
              && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
          <p
            v-if="$v.password.$dirty
              && $v.password.required.$invalid">パスワードを入力してください</p>
          <p
            v-if="user.login.isBanned && helper.master">パスワードを{{ helper.master.system.ban.count }}回間違えたため、{{ user.login.unbannedTime }}までログインが制限されています。<br>制限を解除したい場合は運営まで連絡してください。</p>
          <p
            v-if="user.login.isFailed === 1 && !user.login.isBanned">入力されたメールアドレスは登録されていません</p>
          <p
            v-if="user.login.isFailed === 2 && !user.login.isBanned">入力されたパスワードが間違っています</p>
          <spacer :y="2"/>
          <div>
            <basic-btn
              :disabled="!submitFlag"
              :width="'full'"
              @click="login">ログイン</basic-btn>
            <spacer :y="1"/>
            <div class="text-center">
              <router-link
                to="/forgot-password/"
                class="fs-xs fc-black"><u>パスワードをお忘れですか？</u></router-link>
            </div>
            <spacer :y="6"/>
            <router-link
              to="/signup/"
              class="fs-xs fc-black">
              <basic-btn
                :type="'bdr'"
                :width="'full'">
                新規会員登録
              </basic-btn
            ></router-link>

            <spacer :y="1"/>
            <div class="text-center">
              <router-link
                to="/"
                class="fs-xs fc-black"><u>トップページへ戻る</u></router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import {
  BasicBtn,
  Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';


export default {
  name: 'Login',
  mixins: [cf],
  components: {
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        mail: false,
        password: false,
      },
      invalids: {
        mail: true,
        password: true,
      },
      isExpired: false, // 有効期限切れでリダイレクトされた
      client_id: null,
      client_secret: null,
      host: null,
      redirect_uri: null,
      code: null,
    };
  },
  created() {
    const query = this.$route.query;
    if (query && query.expired) this.isExpired = true;

    // LINEログイン関連の値セット
    this.client_id = process.env.VUE_APP_LINE_LOGIN_CLIENT_ID;
    this.client_secret = process.env.VUE_APP_LINE_LOGIN_CLIENT_SECRET;
    this.host = location.origin;
    this.redirect_uri = `${this.host}/callback-line/`;
  },
  setup() {
    const mail = ref('');
    const password = ref('');
    const rules = {
      mail: { required, email }, // メールは必須・email形式
      password: { required }, // パスワードは必須
    };
    const $v = useVuelidate(rules, { mail, password });
    return { mail, password, $v };
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !this.invalids.password;
    },
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    lineLogin() {
      const params = {
        response_type: 'code',
        client_id: this.client_id,
        redirect_uri: encodeURIComponent(this.redirect_uri),
        scope: 'profile%20openid',
        bot_prompt: 'aggressive',
      };
      // 乱数生成
      const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let randStr = '';
      for (let i = 0; i < 24; i += 1) {
        randStr += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      params.state = randStr;
      // stateをlocalStorageに保存
      cf.saveLocalStorage({ lineState: randStr }, 'cbk');
      // urlにパラメータ付与
      let url = 'https://access.line.me/oauth2/v2.1/authorize?';
      Object.keys(params).forEach((k, i) => {
        if (i !== 0) url += '&';
        url += `${k}=${params[k]}`;
      });
      // lineログインにリダイレクト
      window.location.href = url;
    },

    /** サブミット */
    login(event) {
      if (event) event.preventDefault();
      // validateチェックは入力時に行われてる
      const args = {
        loginType: 'default',
        email: this.mail,
        password: this.password,
      };
      this.$store.dispatch('user/login/submit', args);
    },
  },
};
</script>

<style lang="scss" module="s">
.line_login {
  $iconSize: 50px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  position: relative;
  background-color: #06C755;
  cursor: pointer;
  img {
    display: block;
    width: $iconSize;
    height: $iconSize;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, .08);
  }
  .text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: bold;
    z-index: 1;
  }
  .mask {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: $iconSize;
    background-color: rgba(0, 0, 0, .1);
    opacity: 0;
    border-radius: 4px;
    transition: all .3s;
  }
  &:hover {
    .mask {
      opacity: 1;
    }
  }
}

// ログイン失敗時
.vibration {
  animation: vibration .1s  infinite;
}
@keyframes vibration {
  0% {transform: translate(0px, 0px) rotateZ(0deg)}
  25% {transform: translate(2px, 2px) rotateZ(1deg)}
  50% {transform: translate(0px, 2px) rotateZ(0deg)}
  75% {transform: translate(2px, 0px) rotateZ(-1deg)}
  100% {transform: translate(0px, 0px) rotateZ(0deg)}
}
</style>
