<template>
  <div class="container">
    <div
      v-if="shop">
      <div class="mb-3 fw-bold fs-lg">{{ shop.name }}</div>
      <div class="separator"/>

      <spacer :y="6"/>

      <div :class="s.top">
        <div>
          <div
            class="bg-img bg-img-cover"
            :class="s.img"
            :style="`background-image: url(${shop.images[0]?.url || '/img/noimg.png'});`"/>
        </div>
        <div>
          <div
            class="fw-bold"
            v-html="shop.summary"/>
          <div
            class="mt-3"
            v-html="shop.description"/>
        </div>
      </div>

      <spacer :y="8"/>

      <div class="py-2 px-3 fs-sm fc-gray fw-bold bg-gray-secondary rounded-top-2 bdr-b">店舗詳細</div>
      <spacer :y="4"/>
      <div :class="s.contents">
        <div>
          <ul :class="s.table">
            <li
              v-for="item in tableItems"
              :key="item.name">
              <div>{{ item.label }}</div>
              <div v-if="item.name === 'address'">{{ formatAddress(shop) }}</div>
              <div 
                v-else
                v-html="shop[item.name] || '-'"/>
            </li>
          </ul>
        </div>
        <div v-if="shop.line_bot_id" class="bg-yellow p-3">
          <div class="fw-bold text-center mb-3">\<i class="fa-brands fa-line mx-2"/>公式LINE /</div>
          <img :src="`https://qr-official.line.me/gs/M_${shop.line_bot_id.replace('@', '')}_GW.png?oat_content=qr`">
        </div>
      </div>

      <spacer :y="8"/>

      <div class="py-2 px-3 fs-sm fc-gray fw-bold bg-gray-secondary rounded-top-2 bdr-b">販売車両</div>
      <spacer :y="4"/>
      <div>
        <div v-if="!shop.salesCars.length">現在、この店舗にて販売中の車両はありません。</div>
        <ul
          v-if="shop.salesCars.length"
          :class="s.cars">
          <li
            v-for="car in shop.salesCars"
            :key="car.id">
            <router-link :to="`/sales-car/${car.slug}/`">
              <CarCard
                :carData="car"/>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Spacer,
  CarCard,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'shop-detail',
  mixins: [cf],
  components: {
    Spacer,
    CarCard,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: false,
        },
      },
      shop: null,
      tableItems: [
        {
          name: 'tel',
          label: 'TEL',
        },
        {
          name: 'fax',
          label: 'FAX',
        },
        {
          name: 'holiday',
          label: '定休日',
        },
        {
          name: 'hours',
          label: '営業時間',
        },
        {
          name: 'address',
          label: '所在地',
        },
      ],
    };
  },
  created() {
    this.getShop();
  },
  methods: {
    getShop() {
      const slug = this.$route.params.slug;
      if (!slug) return;

      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        slug,
        withSalesCars: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/shop/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.shop = res.detail;
          if (this.shop) this.setBreadCrumb();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    setBreadCrumb() {
      const data = {
        items: [
          {
            label: '販売店一覧',
            path: '/shop',
          },
          {
            label: this.shop.name,
            path: `/shop/${this.shop.slug}/`,
          },
        ],
      };
      this.$store.dispatch('breadCrumb/setItem', data);
    },
  },
};
</script>

<style lang="scss" module="s">
.top {
  display: flex;
  > * {
    &:first-child {
      width: 30%;
      max-width: 300px;
      margin-right: 24px;
      .img {
        width: 100%;
        padding-top: 75%;
      }
    }
    &:last-child {
      flex: 1;
    }
  }
}

.contents {
  display: flex;
  > * {
    &:first-child {
      flex: 1;
      margin-right: 24px;
    }
    &:nth-child(2) {
      width: 25%;
      max-width: 300px;
    }
  }
}

.table {
  border: 1px solid var(--border-gray);
  > li {
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid var(--border-gray);
    }
    > * {
      padding: 4px 8px;
    }
    :first-child {
      width: calc(4.5em + 8px * 2);
      background-color: var(--bg-gray-secondary);
    }
    :last-child {
      flex: 1;
    }
  }
}

.cars {
  display: flex;
  flex-wrap: wrap;
  > li {
    $mgn: 12px;
    width: calc((100% - $mgn * 5) / 6);
    &:not(:nth-child(6n)) {
      margin-right: $mgn;
    }
    &:not(:nth-child(-n+6)) {
      margin-top: $mgn;
    }
  }
}
</style>
