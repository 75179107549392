<template>
  <transition name="fade" mode="out-in">
    <div class="modal loadings" v-if="isShow">
      <!-- loadingはbgクリックで閉じさせない -->
      <div class="modal__bg" :class="$style.wrap"></div>
      <!-- modalNameに応じて表示するコンポーネントを変化 -->
      <component v-bind:is="loadings.modalName" />
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

// 個別モーダルコンポーネントの登録
// import modalLoadingBallClipRotate from './loadings/BallClipRotate.vue';
// import modalLoadingBallScaleRipple from './loadings/BallScaleRipple.vue';
import modalLoadingBallScaleRippleMultiple from './loadings/BallScaleRippleMultiple.vue';

export default {
  name: 'LoadingsModal',
  components: {
    // modalLoadingBallClipRotate,
    // modalLoadingBallScaleRipple,
    modalLoadingBallScaleRippleMultiple,
  },
  computed: {
    ...mapState('modal', ['loadings']),
    isShow() {
      return this.loadings.modalName !== '';
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#fff, .4);
  // background-color: rgba(0, 0, 0, .4);
  z-index: 6;
}
</style>
