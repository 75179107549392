<template>
  <div class="container sm">
    <spacer :y="12"/>
    <div class="top" v-if="isTop">
      <form
        class=""
        autocomplete="off">
        <h2 class="text-center fs-3 fw-bold">新規会員登録</h2>
        <spacer :y="5"/>

        <!-- email -->
        <div class="form-floating">
          <input
            id="mail"
            class="form-control rounded-bottom-0"
            style="border-bottom: none; margin-bottom: -1px;"
            type="email"
            name="mail"
            placeholder="メールアドレス"
            v-model.trim="$v.mail.$model"
            v-bind:disabled="flg.regist"
            v-bind:class="{ input_error: validationFlags.mail }"
            v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
          <label
            class="form-label"
            for="email">メールアドレス</label>
        </div>
        <div class="form-floating">
          <input
            id="username"
            class="form-control rounded-top-0 rounded-bottom-0"
            style="border-bottom: none; margin-bottom: -1px;"
            type="text"
            name="name"
            placeholder="ユーザーネーム"
            v-model.trim="$v.username.$model"
            v-bind:disabled="flg.regist"
            v-bind:class="{ input_error: validationFlags.username }"
            v-on:input="invalidsCheck('username', $v.username.$invalid)">
          <label for="username">ユーザーネーム</label>
        </div>
        <div class="form-floating">
          <input
            id="tel"
            class="form-control rounded-top-0"
            type="text"
            name="tel"
            placeholder="電話番号"
            v-model.trim="$v.tel.$model"
            v-bind:disabled="flg.regist"
            v-bind:class="{ input_error: validationFlags.tel }"
            v-on:input="invalidsCheck('tel', $v.tel.$invalid)">
          <label for="tel">電話番号</label>
        </div>


        <p
          v-if="$v.mail.$dirty && $v.mail.email.$invalid"
          class="fc-red fs-xs text-center mt-3">
            正しいメールアドレスの形式で入力してください
        </p>

        <p
          v-if="$v.username.$dirty && $v.username.$invalid"
          class="fc-red fs-xs text-center mt-3">
            ユーザーネームを入力してください
        </p>
        <p
          v-if="$v.tel.$dirty && $v.tel.$invalid"
          class="fc-red fs-xs text-center mt-3">
            電話番号を入力してください
        </p>

        <p
          class="fs-xs text-center mt-3"
          v-if="!flg.exitst && flg.regist">
          ご入力いただいたメールアドレスにメールを送信しました。メールに記載されているURLからパスワードを登録してください。
        </p>

        <p
          class="fs-xs text-center mt-3"
          v-if="flg.exists && !flg.regist">
          送信いただいたメールアドレスは登録済みです。<br>
          <a href="/login/" class="link">ログインページ</a>
          よりログインしてください。<br>パスワード設定がまだの場合は、メールアドレス登録時に送信されたメールに記載のURLよりパスワード設定を行ってください。
        </p>

        <spacer :y="5"/>

        <div v-if="!flg.regist">
          <basic-btn
            @click="submit"
            :width="'full'"
            v-bind:disabled="!submitFlag">送信する</basic-btn>
        </div>
      </form>

    </div>

    <!-- 下層テンプレート読み込み（要ルータchildren） -->
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import {
  BasicBtn,
  Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Signup',
  mixins: [cf],
  components: {
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      isTop: false,
      flg: {
        exists: false,
        regist: false,
        error: false,
      },
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
      // 有効期限切れでリダイレクトされた
      isExpired: false,
    };
  },
  computed: {
    ...mapState(['user']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !this.invalids.username;
    },
  },
  setup() {
    const mail = ref('');
    const username = ref('');
    const tel = ref('');

    const rules = {
      mail: { required, email },
      username: { required },
      tel: { required },
    };

    const $v = useVuelidate(rules, { mail, username, tel });

    return {
      mail,
      username,
      tel,
      $v,
    };
  },
  created() {
    this.checkIsTop();
  },
  watch: {
    $route() {
      this.checkIsTop();
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    checkIsTop() {
      const isTop = this.$route.name === 'Signup';
      this.isTop = isTop;
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      if (!this.submitFlag) return;

      this.showLoading();

      // validateチェックは入力時に行われてる
      const data = {
        email: this.mail,
        username: this.username,
        tel: this.tel,
        sendEmail: 1,
        flag: 10,
        role: 1,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/create',
        data,
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
          // 初期化
          this.flg.regist = false;
          this.flg.exists = false;
          // 初期登録
          if (!res.exists) this.flg.regist = true;
          // 登録済み
          else this.flg.exists = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
