<template>
  <div class="bg-gray-secondary p-3 rounded-2">
    <div class="fw-bold pb-2 bdr-b d-flex justify-content-between align-items-center flex-wrap">
      <div><i class="fa-solid fa-magnifying-glass me-2"/>絞り込み</div>
      <div
        @click="clearParams"
        class="fs-xs fc-red text-decoration-underline pointer">検索条件をクリア</div>
    </div>
    <ul class="card-body">
      <li
        v-for="(item, i) in searchColumns"
        :key="i"
        class="mt-4"
        :class="i !== 0 ? 'mt-3' : ''">
        <div class="fs-xs mb-1 fw-bold fc-gray">{{ item.label }}</div>
        <div class="separator mb-2"/>
        <!-- select -->
        <div
          v-if="item.type === 'select'"
          :class="s.select_wrap">
          <select
            class="form-control form-control-sm"
            :name="item.name" :id="item.name"
            v-model="values[item.name]">
            <option :value="null" v-if="item.name === 'vehicle' && !values.brand">ブランドを選択してください</option>
            <option :value="null" v-else>未選択</option>
            <option
              v-for="opt in adjustedOptions(item)"
              :key="opt.value"
              :value="opt.value">{{ opt.label }}</option>
          </select>
        </div>

        <!-- range -->
        <div
          v-if="item.type === 'range'"
          class="d-flex align-items-center">
          <select
            class="flex-grow-1 form-control form-control-sm"
            name="" id=""
            v-model="values[item.name].min">
            <option
              v-for="(minOpt, j) in adjustedOptions(item, 'min')"
              :key="j"
              :value="minOpt.value">{{ minOpt.label }}</option>
          </select>
          <div class="mx-2">〜</div>
          <select
            class="flex-grow-1 form-control"
            name="" id=""
            v-model="values[item.name].max">
            <option
              v-for="(maxOpt, j) in adjustedOptions(item, 'max')"
              :key="j"
              :value="maxOpt.value">{{ maxOpt.label }}</option>
          </select>
        </div>

        <!-- カラー -->
        <div
          v-if="item.type === 'color'">
          <div class="mt-3">
            <ul :class="s.color_list">
              <li
                v-for="(c, j) in colors"
                :key="j">
                <div
                  :class="[
                    s.color_label,
                    'car-color',
                    `car-${c.value}`,
                    c.value.includes('metal') ? `car-metal` : '',
                    values.color_types.includes(c.value) ? s.active : '',
                  ]"
                  @click="selectColor(c.value)"><span/><i class="fa-solid fa-circle-check"/><span/></div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'search-column',
  mixins: [cf],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    baseUrl: {
      type: String,
      default: '/sales-car/',
    },
  },
  data() {
    return {
      values: {
        brand: null,
        vehicle: null,
        price: {
          min: 0,
          max: 0,
        },
        mileage: null,
        regist_year: null,
        shape_type: null,
        capacity: null,
        displacement: {
          min: 0,
          max: 0,
        },
        mission: null,
        repair_history: null,
        color_types: [],
      },
      brands: [],
      searchItems: [
        {
          name: 'brand',
          label: 'ブランド',
          type: 'select',
        },
        {
          name: 'vehicle',
          label: '車種',
          type: 'select',
        },
        {
          name: 'price',
          label: '価格',
          type: 'range',
          range: {
            min: 5,
            max: 1000,
            unit: {
              5: 5,
              90: 10,
              default: 100,
            },
            suffix: '万円',
          },
        },
        {
          name: 'mileage',
          label: '走行距離',
          type: 'select',
        },
        {
          name: 'regist_year',
          label: '年式',
          type: 'select',
        },
        {
          name: 'shape_type',
          label: 'ボディタイプ',
          type: 'select',
        },
        {
          name: 'capacity',
          label: '乗車定員',
          type: 'select',
        },
        {
          name: 'displacement',
          label: '排気量',
          type: 'range',
          range: {
            min: 500,
            max: 4000,
            unit: {
              default: 500,
            },
            suffix: 'cc',
          },
        },
        {
          name: 'mission',
          label: 'ミッション',
          type: 'select',
        },
        {
          name: 'repair_history',
          label: '修復歴',
          type: 'select',
        },
        {
          name: 'color_types',
          label: 'カラー',
          type: 'color',
        },
      ],
    };
  },
  created() {
    this.getBrands();
  },
  computed: {
    ...mapState(['helper']),
    vehicles() {
      const targetBrand = this.brands.find((b) => b.name === this.values.brand);
      return targetBrand ? targetBrand.vehicles : [];
    },
    colors() {
      const colors = this.helper.master.labels?.cars.colors || [];
      return Object.keys(colors).map((key) => Object({ label: colors[key].label, value: key }));
    },
    searchColumns() {
      if (!this.columns.length) return this.searchItems;
      return this.columns.map((col) => this.searchItems.find((item) => item.name === col));
    },
  },
  watch: {
    $route: {
      handler() {
        const query = this.$route.query;

        const queryKeys = Object.keys(query);
        Object.keys(this.values).forEach((key) => {
          const item = this.searchColumns.find((itemObj) => itemObj.name === key);
          if (item) {
            if (item.type === 'range') {
              if (queryKeys.includes(`${key}Min`)) this.values[key].min = query[`${key}Min`] || 0;
              if (queryKeys.includes(`${key}Max`)) this.values[key].max = query[`${key}Max`] || 0;
            }
            if (item.type === 'select') this.values[key] = query[key] ? decodeURIComponent(query[key]) : null;
            if (item.type === 'color') {
              try {
                this.values.color_types = JSON.parse(query.color_types);
              } catch {
                this.values.color_types = [];
              }
            }
          }
        });
      },
      immediate: true,
    },
    values: {
      handler() {
        const query = {};

        Object.keys(this.values).forEach((key) => {
          const item = this.searchColumns.find((itemObj) => itemObj.name === key);
          if (item) {
            if (item.type === 'select' && this.values[key]) query[key] = encodeURIComponent(this.values[key]);
            if (item.type === 'range') {
              if (this.values[key].min) query[`${key}Min`] = this.values[key].min;
              if (this.values[key].max) query[`${key}Max`] = this.values[key].max;
            }
            if (item.type === 'color' && this.values.color_types.length) query.color_types = JSON.stringify(this.values.color_types);
          }
        });

        if (this.values.brand !== decodeURIComponent(this.$route.query.brand)) this.values.vehicle = null;

        this.$router.replace({ path: this.baseUrl, query });
      },
      deep: true,
    },
  },
  methods: {
    adjustedOptions(item, type) {
      let options = [];

      // select
      if (item.type === 'select') {
        if (item.name === 'mileage') { // 走行距離
          // 1万km以上は1万刻み
          const exceptions = [500, 1000, 3000, 5000, 7000];
          exceptions.forEach((num) => { options.push({ label: `${num}km以下`, value: num }); });
          for (let i = 1; i <= 15; i += 1) {
            options.push({ label: `${i}万km以下`, value: i * 10000 });
          }
        } else if (item.name === 'regist_year') { // 年式
          const min = 1980;
          const now = new Date().getFullYear();
          for (let i = now; i >= min; i -= 1) {
            options.push({ label: `${i}年(${this.convertYearToJapaneseEra(i)}年)以降`, value: i });
          }
        } else if (item.name === 'shape_type' || item.name === 'capacity' || item.name === 'mission') {
          const labels = this.helper.master.labels?.cars[item.name];
          if (labels) Object.keys(labels).forEach((key) => { options.push({ label: labels[key], value: key }); });
        } else if (item.name === 'repair_history') {
          options = [
            { label: 'あり', value: 1 },
            { label: 'なし', value: 2 },
          ];
        } else {
          options = this[`${item.name}s`].map((obj) => Object({
            label: obj.label || obj.name,
            value: obj.name,
          }));
        }
      }

      // range
      if (item.type === 'range') {
        const keys = Object.keys(item.range.unit);
        keys.forEach((key) => {
          const max = key === 'default' ? item.range.max : Number(key);
          const unit = item.range.unit[key];
          const count = max / unit;
          for (let i = 1; i <= count; i += 1) {
            if (i * unit >= item.range.min) options.push({ label: `${(i * unit).toLocaleString()}${item.range.suffix || ''}`, value: i * unit });
          }
        });
        if (this.values[item.name].max && type === 'min') options = options.filter((opt) => opt.value < this.values[item.name].max);
        if (this.values[item.name].min && type === 'max') options = options.filter((opt) => opt.value > this.values[item.name].min);

        options.unshift({ label: '指定なし', value: 0 });
      }


      return options;
    },
    getBrands() {
      const params = {
        hasPurchaseCars: 1,
        withVehicles: 1,
        orderBy: 'country',
        order: 'asc',
        orJP: 1, // 日本のブランドはhasでフィルタせず全て返却
      };

      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = res.brands.data
            .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? 1 : -1))
            .sort((a, b) => (a.country > b.country ? 1 : -1));
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    selectColor(value) {
      const isAdd = Boolean(!this.values.color_types.includes(value));
      if (isAdd) this.values.color_types = [...this.values.color_types, value];
      else this.values.color_types = this.values.color_types.filter((c) => c !== value);
    },
    clearParams() {
      this.$router.replace('/sales-car/');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border: none;
}
</style>

<style lang="scss" module="s">
.wrap {
  border: 1px solid var(--border-gray);
  border-radius: 8px;
}

.title {
  padding: 12px 18px;
  border-bottom: 1px solid var(--border-gray);
  background-color: var(--bg-gray);
}

.list {
  padding: 12px;
  > li {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.label {
  font-weight: 700;
  font-size: 12px;
  color: var(--label-secondary);
}

.select_wrap {
  position: relative;
  select {
    padding-right: 24px;
  }
  &::after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(calc(-50% - 2px)) rotate(45deg);
    width: 6px;
    height: 6px;
    border: 1px solid;
    border-color: transparent var(--label-secondary) var(--label-secondary) transparent ;
  }
}

.color_list {
  display: flex;
  flex-wrap: wrap;
  > li {
    width: calc(100% / 6);
    padding: 4px;
    > * {
      cursor: pointer;
      transition: all .3s;
      &:hover {
        opacity: .6;
      }
    }
  }
}
.color_label {
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  position: relative;
  > i {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.active {
    > i {
      display: block;
    }
  }
}
</style>
