<template>
  <div class="container">
    <div class="fw-bold fs-lg mb-2"><i class="fa-regular fa-lightbulb-on me-2"/>お知らせ一覧</div>
    <div class="separator"/>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <ul>
        <li
          v-for="item in news"
          :key="item.id">
          <router-link
            :to="`/news/${item.id}/`">
            <div class="p-3 d-flex fc-black bdr-b">
              <div class="flex-shrink-0 me-2">{{ formatTimestamp(item.publish_date, 'YYYY/MM/DD') }}</div>
              <div class="flex-1">{{ item.title }}</div>
            </div>
          </router-link>
        </li>
      </ul>
    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'news-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: false,
        },
      },
      news: [],
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      this.flag.isLoading.get = true;
      const params = {
        isReleased: 1,
        limit: 10,
      };
      this.axios({
        method: 'GET',
        url: '/v1/news/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.news = res.news.data;
          this.flag.isLoading.get = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get = false;
        });
    },
  },
};
</script>
