<template>
  <ul :class="s.list" class="fs-xs">
    <li v-for="(item, i) in items" :key="i">
      <div>{{ item.label }}</div>
      <div class="fw-bold n2br" v-html="item.value"/>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'table',
  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  border: 1px solid var(--border-gray);

  > li {
    width: 50%;
    display: flex;
    align-items: stretch;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-gray);
    }

    > * {
      padding: 8px 12px;
      &:first-child {
        width: 50%;
        flex-shrink: 0;
        background-color: var(--bg-gray-secondary);
      }
    }
  }
}

@include smView {
  .list {
    display: block;
    > li {
      width: 100%;
    }
  }
}
</style>
