<template>
  <div class="container-xl">
    <div :class="s.wrap">
      <div :class="s.side" v-if="flag.showSearchColumn">
        <SearchColumn/>
        <div
          @click="flag.showSearchColumn = !flag.showSearchColumn"
          class="sp bdr rounded-2 bdr-c-red fc-red fw-bold text-center p-2 pointer mt-5">閉じる</div>
      </div>
      <div :class="s.main">
        <!-- キーワード検索 -->
        <form class="mb-4 input-group" @submit.prevent="updateKeyword()">
          <input
            type="text"
            class="form-control"
            placeholder="キーワードで検索"
            v-model="keyword">
          <button
            class="btn"
            :class="s.btn"
            type="button"><i class="fa-solid fa-magnifying-glass me-2"/>検索</button>
        </form>
        <!-- 順序指定 -->
        <div class="mb-4 d-flex justify-content-end align-items-center">
          <div class="me-2 fs-xs">表示順</div>
          <select
            name="order"
            id="order"
            class="form-control w-150px fs-xs"
            v-model="orderBy"
            @change="updateOrder">
            <option
              v-for="(opt, i) in orderOptions"
              :key="i"
              :value="opt.name">{{ opt.label }}</option>
          </select>
        </div>
        <div
          @click="flag.showSearchColumn = !flag.showSearchColumn"
          class="sp bdr rounded-2 bdr-c-red fc-red fw-bold text-center p-2 pointer">検索条件を変更する</div>
        <!-- 車一覧 -->
        <Loader :flag="flag.isLoading.get">
          <ul :class="s.list" v-if="lists.length">
            <li v-for="item in lists" :key="item.id">
              <router-link :to="`/sales-car/${item.slug}/`">
                <CarListItem
                  :carData="item"/>
              </router-link>
            </li>
          </ul>
          <div
            v-else-if="!flag.isLoading.get"
            class="fw-bold ms-3">お探しの条件に該当する車両はありませんでした。</div>
        </Loader>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import {
  CarListItem,
  SearchColumn,
  Loader,
  // Spacer,
} from '@/components/parts';

export default {
  name: 'Home',
  components: {
    SearchColumn,
    CarListItem,
    Loader,
    // Spacer,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: false,
        },
        showSearchColumn: false,
      },
      lists: [],
      keyword: '',
      orderBy: 'newer',
      orderOptions: [
        {
          name: 'newer',
          label: '新着順',
          orderBy: 'created_at',
          order: 'desc',
        },
        {
          name: 'older',
          label: '古い順',
          orderBy: 'created_at',
          order: 'asc',
        },
        {
          name: 'price-all-heighter',
          label: '総支払い金額　高い順',
          orderBy: 'price_total',
          order: 'desc',
        },
        {
          name: 'price-all-lower',
          label: '総支払い金額　低い順',
          orderBy: 'price_total',
          order: 'asc',
        },
      ],
    };
  },
  created() {
    this.keyword = this.$route.query.keyword ? decodeURIComponent(this.$route.query.keyword) : '';
    this.getSalesCars();
  },
  mounted() {
    if (window.innerWidth >= 767) this.flag.showSearchColumn = true;
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    getParams() {
      const query = this.$route.query;
      const params = {};

      let searchedByPrice = false;
      Object.keys(query).forEach((key) => {
        if (key === 'brand' || key === 'vehicle') params[`${key}Name`] = decodeURIComponent(query[key]);
        else if (key === 'order') this.orderBy = query[key];
        else if (key === 'color_types') {
          try {
            params.color_types = JSON.parse(query.color_types);
          } catch {
            params.color_types = [];
          }
        } else if (key !== 'keyword') params[key] = decodeURIComponent(query[key]);
        if (key.includes('price')) searchedByPrice = true;
      });

      if (this.keyword) {
        params.keyword = this.keyword.replace(/　/g, ' ').split(' ');
        params.targetColumn = ['brand.name', 'brand.label', 'vehicle.name', 'grade', 'grade_comment', 'color_name'];
      }

      if (searchedByPrice) params.priceSearchType = 1;

      const orderOption = this.orderOptions.find((opt) => opt.name === this.orderBy);
      params.orderBy = orderOption?.orderBy || 'created_at';
      params.order = orderOption?.order || 'desc';

      return params;
    },
  },
  watch: {
    $route: {
      handler() {
        this.getSalesCars();
      },
      immediate: false,
    },
  },
  methods: {
    getSalesCars() {
      this.flag.isLoading.get = true;
      this.axios({
        method: 'GET',
        url: '/v1/salesCar/get/list',
        params: this.getParams,
      })
        .then((response) => {
          const res = response.data;
          this.lists = res.salesCars.data;
          this.flag.isLoading.get = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get = false;
        });
    },
    updateKeyword() {
      const query = {
        ...this.$route.query,
        keyword: encodeURIComponent(this.keyword),
      };
      this.$router.replace({ path: '/sales-car/', query });
    },
    updateOrder() {
      const query = {
        ...this.$route.query,
        order: this.orderBy,
      };
      this.$router.replace({ path: '/sales-car/', query });
    },
  },
};
</script>

<style lang="scss" module="s">
// レイアウト
.wrap {
  display: flex;
  padding-top: 32px;
  position: relative;
}
.main {
  flex: 1;
  margin-left: 32px;
  @include smView {
    margin-left: 0;
  }
}
.side {
  width: calc(100% / 4);
  max-width: 300px;
  background-color: #ffffff;
  @include smView {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: none;
    padding: calc(var(--header-height) + 24px) 24px 0px;
    height: 100vh;
    overflow: scroll;
  }
}

.btn {
  color: #ffffff;
  background-color: var(--primary-red);
  transition: all .3s;
  &:hover {
    color: #ffffff;
    background-color: var(--primary-red);
    opacity: .4;
  }
}

// リスト
.list {
  $mgn: 32px;
  > li {
    > * {
      display: block;
      padding: $mgn 0;
    }
    &:not(:first-child) {
      border-top: 1px solid var(--border-gray);
    }
  }
}
</style>
