/**
* 共通ヘルパー
*/
// import axios from '@/plugins/axios';

const actions = {
  setItem({ commit }, data) {
    commit('putItem', data);
  },
};


const mutations = {
  putItem(state, data) {
    if (!data.items.length) {
      state.items = [];
      return;
    }

    state.items = [
      {
        label: 'トップ',
        path: '/',
      },
      ...data.items,
    ];
  },
};


const state = {
  items: [],
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
