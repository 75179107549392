<template>
  <div>
    <h2 class="fw-bold fs-3 text-center">パスワード設定</h2>

    <spacer :y="5"/>

    <div class="text-center" v-if="invalid">
      <p class="fc-red fw-bold">アクティベートキーが無効です。</p>
      <p class="fs-xs mt-2">メールアドレス登録時のメールに記載されたURLから再度アクセスするか、ログインをお試しください。</p>
    </div>

    <form
      class="d-flex flex-column aligin-items-center"
      autocomplete="off"
      v-if="!invalid">

      <div v-if="!flag.sended">
        <div class="form-floating">
          <input
            id="password"
            type="password"
            name="password"
            class="form-control"
            placeholder="パスワード"
            v-model.trim="$v.password.$model"
            v-bind:class="{ input_error: validationFlags.password }"
            v-on:input="invalidsCheck('password', $v.password.$invalid)">
          <label for="password">パスワード</label>
        </div>

        <p
          class="fs-xs fc-red text-center mt-3"
          v-if="$v.password.$dirty && $v.password.required.$invalid">
          パスワードを入力してください</p>
        <p
          class="fs-xs fc-red text-center mt-3"
          v-if="$v.password.$dirty && $v.password.minLength.$invalid">
          パスワードは8文字以上で設定してください</p>

        <spacer :y="5"/>

        <div v-if="!flag.sended">
          <basic-btn
            @click="submit"
            :width="'full'"
            :disabled="!submitFlag">登録する</basic-btn>
        </div>

      </div>
      <div
        v-if="flag.sended && flag.registed">
        <p class="text-center fs-xs">
          パスワードを登録しました。<br>
          <router-link :to="'/login/?isRegist=true'">ログインページ</router-link>よりログインしてください。
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import {
  BasicBtn,
  Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';


export default {
  name: 'SignupPassword',
  mixins: [cf],
  components: {
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        password: false,
      },
      // 無効フラグ
      invalids: {
        password: true,
        minLength: true,
      },
      // パラメータなし等で無効
      invalid: true,
      activatekey: null,
      flag: {
        sended: false,
        registed: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.activate) {
      this.invalid = false;
      this.activatekey = query.activate;
    }
  },
  setup() {
    const password = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      password: {
        required,
        minLength: minLength(8),
      },
    };

    const $v = useVuelidate(rules, { password });
    return { password, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.password;
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      if (!this.submitFlag) return;

      this.showLoading();
      // validateチェックは入力時に行われてる
      const data = {
        flag: 1,
        password: this.password,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/create/password',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.registed) this.flag.registed = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.invalid = true;
        })
        .finally(() => {
          this.flag.sended = true;
          this.hideLoading();
        });
    },
  },
};
</script>
