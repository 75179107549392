<template>
  <div class="container sm">
    <spacer :y="10"/>
    <p class="text-center" v-if="user?.login.isLoading">ログイン処理完了後、自動で遷移します。少々お待ちください。</p>
    <div v-if="!user?.login.isLoading && user?.id && user">
      <p class="text-center">ログイン中にエラーが発生しました。再度お試しください。</p>
      <spacer :y="2"/>
      <router-link
        to="/login/"
        class="fs-xs fc-black">
        <basic-btn
          :width="'full'">
          ログイン
        </basic-btn
      ></router-link>
      <spacer :y="2"/>
      <router-link
        to="/"
        class="fs-xs fc-black">
        <basic-btn
          :type="'bdr'"
          :width="'full'">
          トップページへ戻る
        </basic-btn
      ></router-link>
    </div>
    <p class="text-center" v-else-if="!user?.login.isLoading">
      遷移中です...少々お待ちください。
      <br>
      遷移されない場合はエラーが発生している可能性があります。お手数ですが、リロードしてください。
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BasicBtn,
  Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';


export default {
  name: 'Login',
  mixins: [cf],
  components: {
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      client_id: null,
      client_secret: null,
      host: null,
      redirect_uri: null,
      code: null,
    };
  },
  created() {
    // LINEログイン関連の値セット
    this.client_id = process.env.VUE_APP_LINE_LOGIN_CLIENT_ID;
    this.client_secret = process.env.VUE_APP_LINE_LOGIN_CLIENT_SECRET;
    this.host = location.origin;
    this.redirect_uri = `${this.host}/callback-line/`;

    const query = this.$route.query;
    // LINEからのリダイレクトの場合
    if (query.code && query.state) {
      this.code = this.$route.query.code;
      this.lineLogin();
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  methods: {
    /** サブミット */
    lineLogin() {
      const ls = cf.getLocalStorage('cbk');
      if (!ls.lineState || ls.lineState !== this.$route.query.state) return;
      const args = {
        loginType: 'line',
        code: this.code,
        redirect_uri: this.redirect_uri,
        client_id: this.client_id,
        client_secret: this.client_secret,
      };
      this.$store.dispatch('user/login/submit', args);
    },
  },
};
</script>

<style lang="scss" module="s">
.line_login {
  $iconSize: 50px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  position: relative;
  background-color: #06C755;
  cursor: pointer;
  img {
    display: block;
    width: $iconSize;
    height: $iconSize;
    z-index: 1;
    border-right: 1px solid rgba(0, 0, 0, .08);
  }
  .text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: bold;
    z-index: 1;
  }
  .mask {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: $iconSize;
    background-color: rgba(0, 0, 0, .1);
    opacity: 0;
    border-radius: 4px;
    transition: all .3s;
  }
  &:hover {
    .mask {
      opacity: 1;
    }
  }
}

// ログイン失敗時
.vibration {
  animation: vibration .1s  infinite;
}
@keyframes vibration {
  0% {transform: translate(0px, 0px) rotateZ(0deg)}
  25% {transform: translate(2px, 2px) rotateZ(1deg)}
  50% {transform: translate(0px, 2px) rotateZ(0deg)}
  75% {transform: translate(2px, 0px) rotateZ(-1deg)}
  100% {transform: translate(0px, 0px) rotateZ(0deg)}
}
</style>
