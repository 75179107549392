<template>
  <div :class="s.wrap">
    <div :class="s.img" :style="`background-image: url(${carData.images[0]?.url})`"/>
    <div class="mt-2" :class="s.text">
      <div class="fw-bold">{{ carData.vehicle.name }}</div>
      <div class="fs-xs fc-gray fw-bold">{{ carData.brand.label }}</div>
      <div class="fs-xs mt-2 line-height-1">
        <div :class="s.price_label" class="fs-xxs">支払総額</div>
        <div v-if="!carData.price_ask" class="text-end"><span class="fs-5 fc-red fw-bold me-1">{{ carData.price_total.toLocaleString() }}</span>万円</div>
        <div v-if="carData.price_ask" class="text-end"><span class="fs-5 fc-red fw-bold me-1">応相談</span></div>
      </div>
      <div v-if="!carData.price_ask && carData.price_body" class="fs-xs mt-2">
        <div class="fs-xxs" :class="s.price_label">車両本体価格</div>
        <div class="text-end"><span class="fs-5 fw-bold me-1">{{ carData.price_body.toLocaleString() }}</span>万円</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'car-card',
  props: {
    carData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  border: 1px solid var(--border-gray);
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  color: var(--label-primary);
}
.img {
  width: 100%;
  padding-top: 75%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--bg-gray);
}
.text {
  padding: 0 12px 12px;
}

.price_label {
  width: 80px;
  line-height: 1;
}
.price_value {
  width: 120px;
}
</style>
