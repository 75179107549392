<template>
  <header :class="s.header">
    <div :class="s.wrap">
      <div>
        <router-link
          to='/'
          :class="s.logo">
          <img src="/img/logos/logo_ja.jpg" alt="">
        </router-link>
      </div>
      <!-- <div class="d-flex align-items-center">
        <div v-if="!user.login.isLogin">
          <router-link
            to="/login/"
            class="btn btn-sm btn-outline-light">ログイン</router-link>
        </div>
        <div v-if="user.login.isLogin">
          <div
            class="btn btn-outline-light"
            @click="logout">ログアウト</div>
        </div>
      </div> -->
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GlobalHeader',
  components: {
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  created() {
  },
  methods: {
    logout() {
      this.$store.dispatch('user/login/logout', null, { root: true });
      this.$router.push({ path: '/' });
    },
  },
};
</script>

<style lang="scss" module="s">
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--primary-red);
  height: var(--header-height);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 100;
}

.wrap {
  padding: 0 12px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  img {
    height: calc(var(--header-height) - 12px * 2);
  }
}
</style>
