<template>
  <div :class="s.wrap" v-if="breadCrumb.items.length">
    <div class="container-xl fs-xs" :class="s.container">
      <ul>
        <li
          v-for="(item, i) in breadCrumb.items"
          :key="i">
          <router-link
            :to="item.path">{{ item.label }}</router-link>
          <span v-if="i !== breadCrumb.items.length - 1">/</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'bread-crumbs',
  data() {
    return {};
  },
  computed: {
    ...mapState(['breadCrumb']),
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
}
</style>

<style lang="scss" scoped>
.container-xl {
  padding-top: 8px !important;
  padding-bottom: 4px !important; 
}

ul {
  display: flex;
  flex-wrap: wrap;
  > li {
    display: flex;
    align-items: center;
    a {
      color: var(--label-primary);
      &:hover {
        text-decoration: underline;
      }
    }
    > span {
      padding: 0 12px;
    }
  }
}
</style>
