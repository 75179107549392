<template>
  <div class="container-xl">
    <div :class="s.wrap">
      <div :class="s.side" v-if="flag.showSearchColumn">
        <div class="bg-gray-secondary p-3 rounded-2">
          <div class="fw-bold pb-2 bdr-b d-flex justify-content-between align-items-center flex-wrap">
            <div><i class="fa-solid fa-magnifying-glass me-2"/>絞り込み</div>
            <div
              @click="clearParams"
              class="fs-xs fc-red text-decoration-underline pointer">検索条件をクリア</div>
          </div>
          <ul class="card-body">
            <li class="mt-4">
              <div class="fs-xs mb-1 fw-bold fc-gray">所在地から探す</div>
              <div class="separator mb-2"/>
              <div class="mt-3">
                <div class="fs-xs fc-gray">都道府県</div>
                <select
                  name="" id=""
                  class="form-control form-control-sm"
                  v-model="pref"
                  @change="updateSearchParams('pref', $event)">
                  <option :value="null">選択してください</option>
                  <option
                    v-for="(pref, i) in helper.master.prefs"
                    :key="i"
                    :value="pref.value">{{ pref.label }}</option>
                </select>
              </div>

              <div class="mt-3">
                <div class="fs-xs fc-gray">市町村名</div>
                <input
                  class="form-control form-control-sm"
                  type="text"
                  v-model="city"
                  @keydown.enter="updateSearchParams('city', $event)">
              </div>
            </li>
          </ul>
        </div>
        <div
          @click="flag.showSearchColumn = !flag.showSearchColumn"
          class="sp bdr rounded-2 bdr-c-red fc-red fw-bold text-center p-2 pointer mt-5">閉じる</div>
      </div>
      <div :class="s.main">
        <!-- TODO: backend未実装のためコメントアウト -->
        <!-- キーワード検索
        <form class="mb-4 input-group" @submit.prevent="updateKeyword()">
          <input
            type="text"
            class="form-control"
            placeholder="キーワードで検索"
            v-model="keyword"
            @keydown.enter="updateKeyword()">
          <button
            class="btn"
            :class="s.btn"
            type="button"><i class="fa-solid fa-magnifying-glass me-2"/>検索</button>
        </form> -->
        <!-- <div
          @click="flag.showSearchColumn = !flag.showSearchColumn"
          class="sp bdr rounded-2 bdr-c-red fc-red fw-bold text-center p-2 pointer">検索条件を変更する</div> -->
        <!-- 店舗一覧 -->
        <Loader :flag="flag.isLoading.get">
          <ul :class="s.list" v-if="lists.length">
            <li v-for="item in lists" :key="item.id">
              <router-link :to="`/shop/${item.slug}/`" class="fc-black">
                <div class="bg-gray p-3 bdr-b fw-bold">{{ item.name }}</div>
                <div
                  class="fs-xs mt-3"
                  :class="s.detail">
                  <div>
                    <div
                      :class="s.img"
                      :style="`background-image: url(${item.images[0]?.url || '/img/noimg.png'});`"/>
                    <div>
                      <div class="mb-2" v-html="item.summary"/>
                      <ul :class="s.table">
                        <li>
                          <div>営業時間</div>
                          <div v-html="item.hours"/>
                        </li>
                        <li>
                          <div>定休日</div>
                          <div v-html="item.holiday"/>
                        </li>
                        <li>
                          <div>所在地</div>
                          <div>{{ formatAddress(item) }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div :class="s.line">
                    <div v-if="item.line_bot_id" class="bg-yellow p-2">
                      <div class="fw-bold text-center">\<i class="fa-brands fa-line mx-1"/>公式LINE /</div>
                      <img :src="`https://qr-official.line.me/gs/M_${item.line_bot_id.replace('@', '')}_GW.png?oat_content=qr`">
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <FlexList
                    :count="6"
                    :smCount="3"
                    :mgnX="8"
                    :mgnY="8">
                    <li
                      v-for="car in item.salesCars.filter((_, i) => i < 3)"
                      :key="car.id">
                      <router-link
                        :to="`/sales-car/${car.slug}/`"
                        class="fc-black bdr d-flex flex-column rounded-2 overflow-hidden">
                        <div
                          :class="s.img"
                          class="bg-img bg-img-cover"
                          :style="`background-image: url(${car.images[0]?.url || '/img/noimg.png'});`"/>
                        <div class="p-2 fs-xxs d-flex flex-column justify-content-between flex-1">
                          <div>{{ car.vehicle?.name }}({{ car.brand?.label }})</div>
                          <div>
                            <div class="fc-gray mt-2">支払い総額</div>
                            <div v-if="car.price_ask" class="fw-bold fc-red fs-sm" >応相談</div>
                            <div v-if="!car.price_ask"><span class="fs-sm fw-bold fc-red">{{ car.price_total.toLocaleString() }}</span>万円</div>
                          </div>
                        </div>
                      </router-link>
                    </li>
                  </FlexList>
                </div>
              </router-link>
            </li>
          </ul>
          <div
            v-else-if="!flag.isLoading.get"
            class="fw-bold ms-3">お探しの条件に該当する店舗はありませんでした。</div>
        </Loader>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import {
  Loader,
  FlexList,
  // Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'Home',
  mixins: [cf],
  components: {
    Loader,
    FlexList,
    // Spacer,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: false,
        },
        showSearchColumn: false,
      },
      lists: [],
      keyword: '',
      pref: null,
      city: null,
    };
  },
  created() {
    this.syncParamsData();
  },
  mounted() {
    if (window.innerWidth >= 767) this.flag.showSearchColumn = true;
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    getParams() {
      const query = this.$route.query;
      const params = {
        ...query,
      };
      return params;
    },
  },
  watch: {
    $route: {
      handler() {
        this.getShops();
      },
      immediate: true,
    },
    searchParams: {
      handler() {
        this.getShops();
      },
      deep: true,
    },
  },
  methods: {
    syncParamsData() {
      const query = this.$route.query;
      this.pref = query.pref || null;
      this.city = query.city || null;
      this.keyword = query.keyword ? decodeURIComponent(query.keyword) : '';
    },
    getShops() {
      this.syncParamsData();

      this.flag.isLoading.get = true;
      const params = {
        ...this.getParams,
        withSalesCars: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/shop/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.lists = res.shops.data;
          this.flag.isLoading.get = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get = false;
        });
    },
    clearParams() {
      this.$router.replace({ path: '/shop/', query: {} });
    },
    updateKeyword() {
      const query = {
        ...this.$route.query,
        keyword: encodeURIComponent(this.keyword),
      };
      this.$router.replace({ path: '/shop/', query });
    },
    updateSearchParams(key, e) {
      const query = {
        ...this.$route.query,
      };
      if (key === 'pref' && e.target.value === '選択してください') {
        this.pref = null;
        delete query.pref;
        this.$router.replace({ path: '/shop/', query });
        return;
      }
      query[key] = e.target.value;
      this.$router.replace({ path: '/shop/', query });
    },
  },
};
</script>

<style lang="scss" module="s">
// レイアウト
.wrap {
  display: flex;
  padding-top: 32px;
  position: relative;
}
.main {
  flex: 1;
  margin-left: 32px;
  @include smView {
    margin-left: 0;
  }
}
.side {
  width: calc(100% / 4);
  max-width: 300px;
  background-color: #ffffff;
  @include smView {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: none;
    padding: calc(var(--header-height) + 24px) 24px 0px;
    height: 100vh;
    overflow: scroll;
  }
}

.btn {
  color: #ffffff;
  background-color: var(--primary-red);
  transition: all .3s;
  &:hover {
    color: #ffffff;
    background-color: var(--primary-red);
    opacity: .4;
  }
}

// リスト
.list {
  $mgn: 32px;
  > li {
    &:not(:first-child) {
      padding-top: $mgn;
    }
    > * {
      display: block;
      padding-bottom: $mgn;
    }
  }
}

.detail {
  display: flex;
  > * {
    &:first-child {
      flex: 1;
      padding-right: 12px;
      display: flex;
      align-items: flex-start;
      .img {
        flex-shrink: 0;
        width: 40%;
        padding-top: 30%;
        margin-right: 12px;
        background-position: center;
        background-size: cover;
      }
      > :last-child {
        flex: 1;
      }
    }
    &:nth-child(2) {
      width: 200px;
    }
  }
  .table {
    border: 1px solid var(--border-gray);
    > li {
      display: flex;
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-gray);
      }

      > * {
        padding: 4px 8px;
      }
      :first-child {
        width: calc(4.5em + 8px * 2);
        background-color: var(--bg-gray-secondary);
      }
      :last-child {
        flex: 1;
      }
    }
  }

  @include smView {
    display: block;
    > * {
      &:first-child {
        padding-right: 0;
        margin-bottom: 12px;
        .img {
          display: none;
        }
      }
      &:nth-child(2) {
        margin: 0 auto;
      }
    }
  }
}

.img {
  width: 100%;
  padding-top: 75%;
}
</style>
