import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
/** @Account */
import Login from '@/views/Account/Login.vue';
import Signup from '@/views/Account/Signup.vue';
import SignupPassword from '@/views/Account/SignupPassword.vue';
import ForgotPassword from '@/views/Account/ForgotPassword.vue';
import ResetPassword from '@/views/Account/ResetPassword.vue';
import CallbackLine from '@/views/Account/CallbackLine.vue';

/** @Shops */
import ShopList from '@/views/Shops/List.vue';
import ShopDetail from '@/views/Shops/Detail.vue';

/** @Cars */
import SalesCarsList from '@/views/SalesCars/List.vue';
import SalesCarsDetail from '@/views/SalesCars/Detail.vue';
import PurchaseCarsList from '@/views/PurchaseCars/List.vue';
import PurchaseCarsDetail from '@/views/PurchaseCars/Detail.vue';

/** @News */
import NewsList from '@/views/News/List.vue';
import NewsDetail from '@/views/News/Detail.vue';

/** @Pages */
import Contact from '@/views/Pages/Contact.vue';

/** @NotFound */
import NotFound from '@/views/404.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    children: [
      {
        path: '/signup/password',
        name: 'signup/password',
        component: SignupPassword,
      },
    ],
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'パスワード再発行',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'パスワード再設定',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/callback-line',
    name: 'CallbackLine',
    component: CallbackLine,
    meta: {
      title: 'LINEログイン中',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/shop',
    name: 'ShopsList',
    component: ShopList,
    meta: {
      breadCrumbs: [
        {
          label: '販売店一覧',
          path: '/shop/',
        },
      ],
    },
  },
  {
    path: '/shop/:slug',
    name: 'ShopDetail',
    component: ShopDetail,
    meta: {
      breadCrumbs: [], // DBからデータ取得後にセットするのでここでは空
    },
  },
  {
    path: '/sales-car',
    name: 'SalesCarsList',
    component: SalesCarsList,
    meta: {
      breadCrumbs: [
        {
          label: '販売車両一覧',
          path: '/sales-car/',
        },
      ],
    },
  },
  {
    path: '/sales-car/:slug',
    name: 'SalesCarsDetail',
    component: SalesCarsDetail,
    meta: {
      breadCrumbs: [], // DBからデータ取得後にセットするのでここでは空
    },
  },
  {
    path: '/purchase-car',
    name: 'PurchaseCarsList',
    component: PurchaseCarsList,
    meta: {
      breadCrumbs: [
        {
          label: '買取車両一覧',
          path: '/purchase-car/',
        },
      ],
    },
  },
  {
    path: '/purchase-car/:slug',
    name: 'PurchaseCarsDetail',
    component: PurchaseCarsDetail,
    meta: {
      breadCrumbs: [],
    },
  },
  {
    path: '/news',
    name: 'NewsList',
    component: NewsList,
    meta: {
      breadCrumbs: [
        {
          label: 'お知らせ一覧',
          path: '/news/',
        },
      ],
    },
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: {
      breadCrumbs: [],
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      breadCrumbs: [
        {
          label: 'お問い合わせ',
          path: '/contact/',
        },
      ],
    },
  },
  /** @notfound */
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'お探しのページは見つかりませんでした',
      description: '',
      og_image: '',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // ページ遷移時
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    // fromとtoの違いがない場合はスルー
    if (from.path && to.path === from.path) return;
    if (!to.hash) {
      // ハッシュがなく、
      if (savedPosition) { // ブラウザバック
        position = { top: savedPosition.top };
      } else { // ページ遷移
        position = { top: 0 };
      }
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: to.hash };
    }
    return position;
  },
});

export default router;
