<template>
  <component
    :is="tag"
    :href="link && tag === 'a' ? link : ''"
    :target="link && tag === 'a' && openOtherTab ? '_blank' : ''"
    :class="[
      b.wrap,
      b[type],
      b[shape],
      b[size],
      b[color],
      b[width],
      disabled ? b.disabled : '']">
    <i :class="[icon, b.icon]" v-if="icon"/>
    <slot/>
  </component>
</template>

<script>
export default {
  name: 'basic-btn',
  props: {
    type: {
      type: String,
      default: 'bg',
      validator: (value) => ['bg', 'bdr'].includes(value),
    },
    shape: {
      type: String,
      default: 'straight',
      validator: (value) => ['arc', 'straight'].includes(value),
    },
    size: {
      type: String,
      default: 'lg',
      validator: (value) => ['lg', 'sm'].includes(value),
    },
    width: {
      type: String,
      default: 'fit-content',
      validator: (value) => ['min-fix', 'fit-content', 'full'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'a'].includes(value),
    },
    link: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'red',
      validator: (value) => ['red', 'gry'].includes(value),
    },
    openOtherTab: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
  },
};
</script>

<style lang="scss" module="b">
.wrap {
  display: inline-block;
  padding: 11px 24px;
  transition: all .3s;
  cursor: pointer;
  font-size: 16px;
  border-radius: 40px;
  font-weight: 700;
  border: 1px solid var(--primary-red);
  text-align: center;

  &.fit-content {
    width: fit-content;
  }

  &.full {
    width: 100%;
    display: block;
    text-align: center;
  }

  &.min-fix {
    min-width: 280px;
  }

  &.bg {
    background-color: var(--primary-red);
    color: #ffffff;
    &.red {
      background-color: var(--primary-red);
    }
    &.gry {
      background-color: var(--gray);
      border: var(--gray);
    }
  }

  &.bdr {
    border: 1px solid var(--primary-red);
    color: var(--primary-red);
    background-color: transparent;
    &.gry {
      border-color: var(--gray);
      background-color: transparent;
      color: var(--gray);
    }
  }

  &:hover {
    opacity: .4;
  }

  &.straight {
    border-radius: 4px;
  }

  &.sm {
    font-size: 12px;
    padding: 6px 16px 7px;
  }

  &.disabled {
    opacity: .4;
    cursor: default;
  }
}

.icon {
  margin-right: 12px;
}
</style>
