<template>
  <div class="wrapper">
    <div :class="s.wrap">
      <GlobalHeader />
      <div class="contents">
        <BreadCrumbs />
        <router-view />
      </div>
      <GlobalFooter />
      <LoadingsModal />
      <ContentsModal />
      <MessagesModal />
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex';
import {
  GlobalHeader,
  GlobalFooter,
  BreadCrumbs,
} from '@/components/layouts';
import { ContentsModal, MessagesModal, LoadingsModal } from '@/components/modal';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter,
    BreadCrumbs,
    ContentsModal,
    MessagesModal,
    LoadingsModal,
  },
  computed: {
    isModal() {
      const contents = this.$store.state.modal.contents.modalName !== '';
      const loadings = this.$store.state.modal.loadings.modalName !== '';
      const messages = this.$store.state.modal.messages.modalName !== '';
      return contents || loadings || messages;
    },
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
  },
  watch: {
    $route(to) {
      this.$store.dispatch('breadCrumb/setItem', { items: to.meta.breadCrumbs || [] });
    },
  },
  methods: {
  },
};
</script>


<style lang="scss">
@import './assets/scss/fonts.scss';
@import './assets/scss/_variables.scss';
@import './assets/scss/_reset.scss';
@import './assets/scss/_mq.scss';
@import './assets/scss/modal.scss';
@import './assets/scss/style.scss';
</style>

<style lang="scss" module="s">
.wrap {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: var(--header-height);
}
</style>
