<template>
  <div class="container-xs">
    <spacer :y="8"/>
    <div class="fw-bold fs-lg mb-2">お問い合わせ</div>
    <div class="separator"/>
    <spacer :y="6"/>
    <div class="fs-sm" v-if="!flag.sended">お客様の情報とお問合せ内容を入力し送信してください。<br>数日以内に担当者よりメールにてご連絡させていただきます。</div>
    <div class="fs-sm" v-if="flag.sended">お問い合わせを受け付けました。<br>数日以内に担当者よりメールにてご連絡させていただきます。</div>
    <spacer :y="6"/>
    <ul class="contact-items">
      <li
        v-for="(item, i) in items"
        :key="i">
        <label class="fw-bold fs-xs fc-gray mb-2">{{ item.label }}</label>
        <p class="fs-sm fc-gray mb-2 n2br" v-if="flag.sended">{{ item.value }}</p>
        <input
          v-if="item.type === 'text' && !flag.sended"
          type="text"
          class="form-control"
          v-model="item.value">
        <textarea
          v-if="item.type === 'textarea' && !flag.sended"
          :name="item.name"
          :id="item.name"
          v-model="item.value"
          rows="10"
          class="form-control"/>
      </li>
    </ul>
    <div class="d-flex justify-content-center mt-4" v-if="!flag.sended">
      <button
        class="btn btn-red"
        type="button"
        :disabled="!readySubmit"
        @click="submit">送信する</button>
    </div>
  </div>
</template>

<script>
import {
  Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'contact',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      flag: {
        sended: false,
      },
      items: [
        {
          type: 'text',
          name: 'name',
          label: 'お名前',
          value: '',
        },
        {
          type: 'text',
          name: 'email',
          label: 'メールアドレス',
          value: '',
        },
        {
          type: 'textarea',
          name: 'content',
          label: 'お問い合わせ内容',
          value: '',
        },
      ],
    };
  },
  created() {},
  computed: {
    readySubmit() {
      return this.items.every((item) => item.value !== '');
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    submit() {
      if (!this.readySubmit) return;

      this.showLoading();
      const data = cf.pullOutValues(this.items);
      if (data.content) data.content = data.content.replace(/\r?\n/g, '<br>');

      this.axios({
        method: 'POST',
        url: '/v1/contact/send/',
        data,
      })
        .then(() => {
          this.hideLoading();
          this.flag.sended = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-items {
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
    textarea {
      &:focus {
        border-color: #dee6de;
        box-shadow: none;
      }
    }
  }
}
</style>
