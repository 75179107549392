<template>
  <ul>
    <li
      v-for="(ctg, i) in options"
      :key="ctg.name">
      <div class="fw-bold pb-2 mb-3 mt-5 bdr-b bdr-bold">{{ ctg.label }}</div>
      <ul :class="s.list">
        <li
          v-for="item in ctg.options"
          :key="item.name"
          :class="itemLabel(i, item).length < 11 ? s.quater : s.half">
          <div :class="value?.[i] && value[i].includes(item.name) ? s.active : ''">{{ itemLabel(i, item) }}</div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'option-list',
  props: {
    optionValue: {
      type: String,
    },
  },
  data() {
    return {
      value: [],
    };
  },
  created() {
    try {
      this.value = JSON.parse(this.optionValue);
    } catch {
      this.value = [];
    }
  },
  computed: {
    ...mapState(['helper']),
    options() {
      return this.helper.master.labels?.cars.options.front || [];
    },
  },
  methods: {
    itemLabel(idx, item) {
      return `${item.label}${this.detailLabel(idx, item)}`;
    },
    detailLabel(idx, item) {
      if (!item.detail) return '';
      const detailLabels = Object.keys(item.detail).filter((key) => this.value?.[idx] && this.value[idx].includes(key)).map((key) => item.detail[key]);
      let detailLabelString = '';
      detailLabels.forEach((label, i) => {
        detailLabelString = `${detailLabelString}${i === 0 ? '' : '・'}${label}`;
      });
      return detailLabelString ? `（${detailLabelString}）` : '';
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  > li {
    padding: 2px;
    &.half {
      width: 50%;
    }
    &.quater {
      width: 25%;
    }

    > * {
      border: 1px solid var(--border-gray);
      color: var(--border-gray);
      font-size: 12px;
      padding: 8px 12px;
      font-weight: bold;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &.active  {
        color: var(--label-primary);
        border-color: var(--secondary-red);
        background-color: var(--bg-red);
      }
    }
  }
}

@include smView {
  .list {
    > li {
      &.quater {
        width: 50%;
      }
    }
  }
}
</style>
