<template>
  <div class="container">
    <Loader :flag="flag.isLoading.get">
      <div v-if="!flag.isLoading.get && news">
        <div class="fs-xs fc-gray mb-2">{{ formatTimestamp(news.publish_date, 'YYYY/MM/DD') }}</div>
        <div class="fw-bold fs-lg mb-2">{{ news.title }}</div>
        <div class="separator"/>
        <spacer :y="6"/>
        <div
          class="n2br"
          v-html="news.content"/>
      </div>
      <div v-if="!flag.isLoading.get && !news">
        <NotFound/>
      </div>
    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
} from '@/components/parts';
import NotFound from '@/views/404.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'news-detail',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    NotFound,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      news: null,
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      this.flag.isLoading.get = true;
      const params = {
        id: this.$route.params.id,
        isReleased: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/news/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.news = res.detail;
          if (this.news) this.setBreadCrumb();
          this.flag.isLoading.get = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get = false;
        });
    },
    setBreadCrumb() {
      const data = {
        items: [
          {
            label: 'お知らせ一覧',
            path: '/news/',
          },
          {
            label: this.news.title,
            path: `/news/${this.news.id}/`,
          },
        ],
      };
      this.$store.dispatch('breadCrumb/setItem', data);
    },
  },
};
</script>
