<template>
  <footer :class="s.footer">
    <p><router-link to='/'>CarBank</router-link></p>
    
    <!-- <spacer :y="2"/>

    <div class="fs-xs">
      <router-link
        class="text-decoration-underline"
        to="/login/"
        v-if="!user.login.isLogin">ログインはこちら</router-link>
      <div
        v-else
        class="text-decoration-underline pointer"
        @click="logout">ログアウト</div>
    </div> -->
  </footer>
</template>

<script>
import { mapState } from 'vuex';
// import Spacer from '@/components/parts/Spacer.vue';

export default {
  name: 'GlobalFooter',
  components: {
    // Spacer,
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
  },
  methods: {
    logout() {
      this.$store.dispatch('user/login/logout', null, { root: true });
      this.$router.push({ path: '/' });
    },
  },
};
</script>

<style lang="scss" module="s">
.footer {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 32px;
  margin-top: 56px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  a {
    color: var(--label-primary);
  }
}
</style>
