<template>
  <div>
    <div :class="s.wrap" v-if="carLabels">
      <div :class="s.left">
        <div :class="s.thumb" :style="`background-image: url(${carData.images[0]?.url})`"></div>
      </div>
      <div :class="s.right">
        <div>
          <!-- メイン情報 -->
          <div class="mb-3">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <div :class="s.name">{{ carData.vehicle.name }}</div>
                <div class="fw-bold">{{ carData.brand.label }}</div>
              </div>
              <div :class="s.like"><i class="fa-solid fa-heart"></i></div>
            </div>
            <div :class="s.fs_sm">{{ optionString }}</div>
          </div>

          <!-- 詳細情報 -->
          <div class="mb-3">
            <div class="d-flex align-items-end mb-3">
              <div :class="[s.price, s.all]">
                <div :class="s.label">支払い総額</div>
                <div :class="s.num" v-if="!carData.price_ask"><span>{{ carData.price_total }}</span>万円</div>
                <div :class="s.ask" v-if="carData.price_ask"><span>応相談</span></div>
              </div>
              <div
                v-if="carData.price_body"
                class="d-flex align-items-end">
                <div class="me-3" :class="[s.price, s.detail]">
                  <div :class="s.label">車両本体価格</div>
                  <div :class="s.num"><span>{{ carData.price_body }}</span>万円</div>
                </div>
                <div :class="[s.price, s.detail]">
                  <div :class="s.label">諸経費</div>
                  <div :class="s.num"><span>{{ carData.price_total - carData.price_body }}</span>万円</div>
                </div>
              </div>
            </div>
            <ul :class="s.tags">
              <li v-if="carData.maintenance"><i class="fa-solid fa-tag"/>法定整備：整備付<span v-if="carData.maintenance_comment">（{{ carData.maintenance_comment }}）</span></li>
              <li v-if="carData.warranty === 1 || carData.warranty === 2"><i class="fa-solid fa-tag"/>{{ formatWarranty(carData) }}</li>
            </ul>
            <ul :class="s.details">
              <li>年式｜<span>{{ carData.regist_year }}年</span></li>
              <li>走行距離｜<span>{{ formatMileage(carData) }}</span></li>
              <li>車検｜<span>{{ formatInspection(carData) }}</span></li>
              <li>排気量｜<span>{{ carData.displacement }}cc</span></li>
              <li>修復歴｜<span>{{ carData.repair_history ? 'あり' : 'なし' }}</span></li>
            </ul>
            <ul :class="s.basedata">
              <li>ミッション｜<span>{{ carLabels.mission[carData.mission] }}</span></li>
              <li v-if="carData.color_name">カラー｜<span>{{ carData.color_name }}</span></li>
              <li>ボディタイプ｜<span>{{ carLabels.shape_type[carData.shape_type] }}</span></li>
            </ul>

          </div>


          <!-- btns -->
          <!-- <ul :class="s.btns">
            <li><basic-btn :icon="'fa-solid fa-calendar-days'">来店・試乗予約</basic-btn></li>
            <li><basic-btn :icon="'fa-regular fa-envelope'">お見積もり依頼</basic-btn></li>
          </ul> -->
        </div>
      </div>
    </div>
    <!-- store -->
    <div :class="s.store" v-if="carData.shops?.[0]">
      <div>
        <div :class="s.fs_sm">{{ carData.shops[0].pref }}{{ carData.shops[0].city }}{{ carData.shops[0].town }}</div>
        <div>{{ carData.shops[0].name }}</div>
      </div>
      <div>
        <div :class="s.tel">{{ carData.shops[0].tel }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import BasicBtn from '@/components/parts/BasicBtn.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'car-list-item',
  mixins: [cf],
  props: {
    carData: {
      type: Object,
    },
  },
  components: {
    // BasicBtn,
  },
  computed: {
    ...mapState(['helper']),
    carLabels() {
      if (!this.helper.master.labels) return null;
      return this.helper.master.labels.cars;
    },
    optionString() {
      if (!this.carLabels || !this.carData) return '';
      let selectedOptions = [];
      const options = JSON.parse(this.carData.options);
      const optionData = this.carLabels.options.front;
      optionData.forEach((ctg, i) => {
        const selected = ctg.options.filter((item) => options?.[i].includes(item.name));
        selectedOptions = [...selectedOptions, ...selected];
      });
      let str = '';
      selectedOptions.forEach((opt, i) => {
        str = `${str}${i === 0 ? '' : '／'}${opt.label}`;
      });
      return str;
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  color: var(--black);
  display: flex;
  > * {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}

.left {
  min-width: 200px;
  width: calc(100% / 4);
}

.right {
  flex: 1;
}

.thumb {
  width: 100%;
  padding-top: 75%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--bg-gray-secondary);
}

.name {
  font-weight: bold;
  font-size: 20px;
}
.like {
  font-size: 32px;
  cursor: pointer;
}

.fs_sm {
  font-size: 12px;
}

.price {
  line-height: 1;
  &.all {
    border-right: 1px solid var(--border-gray);
    margin-right: 12px;
    padding-right: 12px;
    .num {
      > span {
        font-size: 32px;
        color: var(--primary-red);
        margin-right: 8px;
      }
    }
    .ask {
      font-size: 24px;
      font-weight: bold;
      margin-top: 16px;
    }
  }
  &.detail {
    .label {
      font-size: 12px;
    }
    .num {
      > span {
        font-size: 20px;
      }
    }
  }

  .label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .num {
    font-size: 12px;
    > span {
      margin-right: 4px;
      font-weight: bold;
    }
  }
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > li {
    font-size: 12px;
    padding: 4px 12px;
    background-color: var(--bg-gray);
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    i {
      margin-right: 8px;
    }
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  > li {
    margin-right: 24px;
    font-size: 12px;
    color: rgba(black, .4);
    font-weight: bold;
    > span {
      color: var(--black);
    }
  }
}

.basedata {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  > li {
    margin-right: 24px;
    font-size: 10px;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
  > li {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }
}

.store {
  color: var(--black);
  background-color: var(--bg-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  border-radius: 4px;
  margin-top: 16px;

  .tel {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-red);
  }
}
</style>
