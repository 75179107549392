<template>
  <div
    :style="style"
    :id="name ? `flex-list_wrap-${name}` : 'flex-list_wrap'"
    :class="[s.wrap, wrap ? s.flex_wrap :  s.flex_nowrap]">
    <ul
      :class="[
        s.list,
        s[`count-${this.count}`],
        s[`sm-count-${this.calcSmCount}`],
      ]"
      :id="name ? `flex-list-${name}` : 'flex-list'">
      <slot/>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'flex-list',
  props: {
    name: {
      type: String,
    },
    wrapPdg: {
      type: String,
      default: '0 0 0 0',
    },
    justify: {
      type: String,
      default: 'center',
    },
    wrap: {
      type: Boolean,
      default: true,
    },
    overflowX: {
      type: String,
      default: 'scroll',
    },
    mgnX: {
      type: Number,
      default: 0,
    },
    smMgnX: {
      type: Number,
      default: 0,
    },
    mgnY: {
      type: Number,
      default: 0,
    },
    smMgnY: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      // max: 12 （超える場合には $countMax 要変更）
      default: 1,
    },
    smCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      justifyContent: 'start',
      ofx: 'scroll',
    };
  },
  mounted() {
    this.checkTagListWidth();
  },
  computed: {
    style() {
      return {
        '--wrap-pdg': this.wrapPdg,
        '--justify-content': this.justifyContent,
        '--overflow-x': this.ofx,
        '--wrap': this.wrap ? 'wrap' : 'nowrap',
        '--mgn-x': this.formatPx(this.mgnX),
        '--sm-mgn-x': this.calcSmMgnX,
        '--mgn-y': this.formatPx(this.mgnY),
        '--sm-mgn-y': this.calcSmMgnY,
        '--count': this.count,
        '--sm-count': this.calcSmCount,
      };
    },
    // smMgn = 0 はない想定
    calcSmMgnX() { 
      const mgn = this.smMgnX || this.mgnX;
      return this.formatPx(mgn);
    },
    calcSmMgnY() {
      const mgn = this.smMgnY || this.mgnY;
      return this.formatPx(mgn);
    },
    calcSmCount() {
      return this.smCount || this.count;
    },
  },
  methods: {
    formatPx(num) {
      return `${num}px`;
    },

    // リストの中身が変更された場合、親要素からcheckTabWidthを叩く必要あり
    checkTagListWidth() {
      this.$nextTick(() => {
        const wrapId = this.name ? `flex-list_wrap-${this.name}` : 'flex-list_wrap';
        const listId = this.name ? `flex-list-${this.name}` : 'flex-list';
        const wrapW = document.getElementById(wrapId).clientWidth;
        const listW = document.getElementById(listId).scrollWidth;
        if (wrapW >= listW && !this.wrap) {
          this.justifyContent = 'center';
          this.ofx = 'visible';
        }
      });
    },
  },
};
</script>

<style lang="scss" module="s">
$countMax: 12;

.wrap {
  box-sizing: border-box;
}

.flex_wrap {
  .list {
    display: flex;
    flex-wrap: var(--wrap);
    justify-content: var(--justify-content);
  
    > li {
      width: calc((100% - (var(--count) - 1) * var(--mgn-x)) / var(--count));
      @include smView {
        width: calc((100% - (var(--sm-count) - 1) * var(--sm-mgn-x)) / var(--sm-count));;
      }
    }
  
    @for $i from 1 through $countMax {
      &.count-#{$i} {
        > li {
          &:not(:nth-child(#{$i}n + 1)) {
            margin-left: var(--mgn-x);
          }
          &:nth-child(n+#{$i + 1}) {
            margin-top: var(--mgn-y);
          }
        }
      }
    }
  
    // smViewの時に影響してしまうのでリセット
    @for $i from 1 through $countMax {
      &.count-#{$i} {
        @include smView {
          > li {
            &:not(:nth-child(#{$i}n + 1)) { margin-left: 0; }
            &:nth-child(n+#{$i + 1}) { margin-top: 0; }
          }
        }
      }
    }
    
    @for $i from 1 through $countMax {
      @include smView {
        // smの時のスタイル
        &.sm-count-#{$i} {
          > li {
            &:not(:nth-child(#{$i}n + 1)) {
              margin-left: var(--sm-mgn-x);
            }
            &:nth-child(n+#{$i + 1}) {
              margin-top: var(--sm-mgn-y);
            }
          }
        }
      }
    }
    
  }
}

// [wip] overflow時の表示
.flex_nowrap {
  width: 100%;
  overflow-x: var(--overflow-x);
  ul {
    &.list {
      padding: var(--wrap-pdg);
      width: max-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .list {
    display: flex;
    overflow: visible;
    justify-content: var(--justify-content);
    > li {
      flex-shrink: 0;
      &:not(:last-child) {
        margin-right: var(--mgn-x);
      }
    }
  }
}
</style>
